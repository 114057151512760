import React from 'react';
import { Form, Container, Button, Modal, Alert, InputGroup, ButtonToolbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { addVoter, getStreets } from '../redux/actions/voterActions';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import 'react-datepicker/dist/react-datepicker.css';

const requiredFields = [
    'LSTNME',
    'FSTNME',
    'MID',
    'ETH',
    'DOB',
    'REGDTE',
    'PTY',
    'PH',
    'EMAIL',
    'ST',
    'SUFFIX_A',
    'STNME',
    'APT',
    'TWN',
    'ZIP',
    'W',
    'd',
    'NOTES',
    'RESULT',
    'SUPP',
    'SIGN',
    'WEDDING',
];

class AddForm extends React.Component {
    constructor(props) {
        super(props);
        this.props.getStreets();
        this.state = {
            showDelete: false,
            showSubmit: false,
            disableInput: true,
            lockIcon: 'unlock',
            lockButton: 'primary',
            voter: {
                DOB: moment().subtract(18, 'y').toDate(),
                REGDTE: new Date(),
                MID: '',
                ETH: '',
                PTY: 'DEM',
                PH: '',
                EMAIL: '',
                SUFFIX_A: '',
                APT: '',
                TWN: 'Linden',
                ZIP: '07036',
                NOTES: '',
                RESULT: '',
                SUPP: 0,
                SIGN: 0,
                WEDDING: 0,
                STNME: '1st Ave'
            },
        };
        this.handleChange = this.handleChange.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.deleteVoter = this.deleteVoter.bind(this);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.streets !== this.props.streets) {
            this.setState({
                streets: this.props.streets,
            });
        }
    };

    createPartyOptions = () => {
        let pty = ['DEM', 'REP', 'UNA', 'CNV', 'CON', 'LIB', 'NAT', 'GRE', 'SSP', 'RFP'];
        let parties = [];
        for (var i = 0; i < pty.length; i++) {
            parties.push(
                <option value={pty[i]} key={i}>
                    {pty[i]}
                </option>
            );
        }
        return parties;
    };

    createStreetOptions = () => {
        let streets = [];
        for (var i = 0; i < this.props.streets.length; i++) {
            streets.push(
                <option value={this.props.streets[i].STNME} key={i}>
                    {this.props.streets[i].STNME}
                </option>
            );
        }
        return streets;
    };

    handleChange = (e) => {
        let voter = this.state.voter;
        voter[e.target.id] = e.target.value;
        this.setState({
            voter: voter,
        });
    };

    setDate = (id, date) => {
        let pass = true;
        if (id === 'DOB' && moment(date).isAfter(moment().subtract(18, 'y'))) {
            pass = false;
        }
        if (moment(date).isAfter(moment())) {
            pass = false;
        }
        if (pass) {
            let voter = this.state.voter;
            console.log(`${id} => ${date}`);
            voter[id] = date;
            this.setState({
                voter: voter,
            });
        }
    };

    isValid = () => {
        let valid = true;
        for (let i = 0; i < requiredFields.length; i++) {
            let field = requiredFields[i];
            if (!(field in this.state.voter)) {
                console.log(field);
                valid = false;
            }
        }
        if (valid) {
            this.setState({ showSubmit: true });
        } else {
            this.setState({ errorOccurred: true, errorMessage: 'Please fill out all required fields.' });
        }
    };

    saveChanges(e) {
        this.setState({ showSubmit: false });
        this.props.addVoter(this.state.voter);
        this.props.history.push(`/dashboard`);
    }

    deleteVoter(e) {}

    render() {
        return (
            <div>
                <Container>
                    <ButtonToolbar className="justify-content-between">
                        <InputGroup>
                            <h2><FontAwesomeIcon icon={['far', 'user-plus']} /> Add New Voter</h2>
                        </InputGroup>
                    </ButtonToolbar>
                    <hr />
                    <Alert variant="warning">
                        <span className="font-weight-bold">WARNING:</span> Only add this voter, if you know they are registered. Double check their name, address and information. If you're not sure,
                        they're not worth adding to the database. It will only be used when exporting lists.
                    </Alert>
                    <hr />
                    <Form.Row>
                        <Form.Group className="col-md-3">
                            <Form.Label htmlFor="LSTNME">
                                Last Name <small className="text-danger">*</small>
                            </Form.Label>
                            <Form.Control type="text" id="LSTNME" name="LSTNME" onInput={this.handleChange} placeholder="Last Name" />
                        </Form.Group>
                        <Form.Group className="col-md-3">
                            <Form.Label htmlFor="FSTNME">
                                First Name <small className="text-danger">*</small>
                            </Form.Label>
                            <Form.Control type="text" id="FSTNME" name="FSTNME" onInput={this.handleChange} placeholder="First Name" />
                        </Form.Group>
                        <Form.Group className="col-md-1">
                            <Form.Label htmlFor="MID">Middle</Form.Label>
                            <Form.Control type="text" id="MID" name="MID" onInput={this.handleChange} placeholder="M.I." />
                        </Form.Group>
                        <Form.Group className="col-md-2 divToggle">
                            <Form.Label htmlFor="ETH">Ethnicity</Form.Label>
                            <Form.Control type="text" id="ETH" name="ETH" onInput={this.handleChange} placeholder="Ethnicity" />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className="col-md-2">
                            <Form.Label htmlFor="DOB">
                                DOB <small className="text-danger">*</small>
                            </Form.Label>
                            <DatePicker selected={this.state.voter.DOB} onChange={(date) => this.setDate('DOB', date)} className="form-control" />
                        </Form.Group>
                        <Form.Group className="col-md-2">
                            <Form.Label htmlFor="REGDTE">Registered</Form.Label>
                            <DatePicker selected={this.state.voter.REGDTE} onChange={(date) => this.setDate('REGDTE', date)} className="form-control" readonly disabled />
                        </Form.Group>
                        <Form.Group className="col-md-2 divToggle">
                            <Form.Label htmlFor="PTY">
                                Party Affiliation <small className="text-danger">*</small>
                            </Form.Label>
                            <Form.Control as="select" id="PTY" name="PTY" onChange={this.handleChange}>
                                {this.createPartyOptions()}
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group className="col-md-3 divToggle">
                            <Form.Label htmlFor="ALTPH">Phone Number</Form.Label>
                            <Form.Control type="tel" id="ALTPH" name="ALTPH" onInput={this.handleChange} placeholder="Phone #" />
                        </Form.Group>
                        <Form.Group className="col-md-3 divToggle">
                            <Form.Label htmlFor="EMAIL">Email</Form.Label>
                            <Form.Control type="email" id="EMAIL" name="EMAIL" onInput={this.handleChange} placeholder="Email Address" />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className="col-md-1 divToggle">
                            <Form.Label htmlFor="ST">
                                Street # <small className="text-danger">*</small>
                            </Form.Label>
                            <Form.Control type="number" id="ST" name="ST" onInput={this.handleChange} placeholder="1" />
                        </Form.Group>
                        <Form.Group className="col-md-1 divToggle">
                            <Form.Label htmlFor="SUFFIX_A">Suffix</Form.Label>
                            <Form.Control type="text" id="SUFFIX_A" name="SUFFIX_A" onInput={this.handleChange} placeholder="Suffix" />
                        </Form.Group>
                        <Form.Group className="col-md-3 divToggle">
                            <Form.Label htmlFor="STNME">
                                Street <small className="text-danger">*</small>
                            </Form.Label>
                            <Form.Control as="select" id="STNME" name="STNME" onChange={this.handleChange}>
                                {this.createStreetOptions()}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="col-md-1 divToggle">
                            <Form.Label htmlFor="APT">Apt #</Form.Label>
                            <Form.Control type="text" id="APT" name="APT" onInput={this.handleChange} placeholder="Apt #" />
                        </Form.Group>
                        <Form.Group className="col-md-2 divToggle">
                            <Form.Label htmlFor="TWN">
                                Town <small className="text-danger">*</small>
                            </Form.Label>
                            <Form.Control type="text" id="TWN" name="TWN" onInput={this.handleChange} defaultValue={this.state.voter.TWN} />
                        </Form.Group>
                        <Form.Group className="col-md-1 divToggle">
                            <Form.Label htmlFor="ZIP">
                                ZIP Code <small className="text-danger">*</small>
                            </Form.Label>
                            <Form.Control type="text" id="ZIP" name="ZIP" onInput={this.handleChange} defaultValue={this.state.voter.ZIP} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className="col-md-2 divToggle">
                            <Form.Label htmlFor="W">
                                Ward <small className="text-danger">*</small>
                            </Form.Label>
                            <Form.Control type="number" id="W" name="W" onInput={this.handleChange} placeholder="Ward" />
                        </Form.Group>
                        <Form.Group className="col-md-2 divToggle">
                            <Form.Label htmlFor="d">
                                District <small className="text-danger">*</small>
                            </Form.Label>
                            <Form.Control type="number" id="d" name="d" onInput={this.handleChange} placeholder="District" />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className="col-lg-12 divToggle">
                            <Form.Label htmlFor="NOTES">Notes / Problem</Form.Label>
                            <Form.Control as="textarea" id="NOTES" name="NOTES" rows="3" onInput={this.handleChange} placeholder="Notes"></Form.Control>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className="col-lg-12 divToggle">
                            <Form.Label htmlFor="RESULT">Result</Form.Label>
                            <Form.Control as="textarea" id="RESULT" name="RESULT" rows="3" onInput={this.handleChange} placeholder="Result"></Form.Control>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className="col-sm-4 divToggle">
                            <Form.Label htmlFor="SUPP">
                                Can we expect their support? <small className="text-danger">*</small>
                            </Form.Label>
                            <Form.Control as="select" id="SUPP" name="SUPP" defaultValue={0} onChange={this.handleChange}>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className="col-sm-4 divToggle">
                            <Form.Label htmlFor="SIGN">
                                Can we put a sign up? <small className="text-danger">*</small>
                            </Form.Label>
                            <Form.Control as="select" id="SIGN" name="SIGN" defaultValue={0} onChange={this.handleChange}>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className="col-sm-4 divToggle">
                            <Form.Label htmlFor="WEDDING">
                                Did the Mayor marry them? <small className="text-danger">*</small>
                            </Form.Label>
                            <Form.Control as="select" id="WEDDING" name="WEDDING" defaultValue={0} onChange={this.handleChange}>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                    <Button variant="primary" id="submitBtn" onClick={this.isValid}>
                        Add Voter <FontAwesomeIcon icon={['far', 'user-plus']} />
                    </Button>
                    <Modal id="confirm-submit" show={this.state.showSubmit} onHide={() => this.setState({ showSubmit: false })} backdrop="static" keyboard={false} centered>
                        <Modal.Header closeButton>
                            <h5 className="modal-title">Confirm Changes</h5>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure you want to make these changes?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="button" variant="primary" name="submit" id="submit" onClick={this.saveChanges}>
                                Save changes
                            </Button>
                            <Button type="button" variant="secondary" data-dismiss="modal" onClick={() => this.setState({ showSubmit: false })}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal id="error" show={this.state.errorOccurred} onHide={() => this.setState({ errorOccurred: false })} backdrop="static" keyboard={false} centered>
                        <Modal.Header className="bg-danger text-white" closeButton>
                            <h5 className="modal-title">Error</h5>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{this.state.errorMessage}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="button" variant="danger" data-dismiss="modal" onClick={() => this.setState({ errorOccurred: false })}>
                                OK
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Container>
            </div>
        );
    }
}

AddForm.propTypes = {
    addVoter: PropTypes.func.isRequired,
    getStreets: PropTypes.func.isRequired,
    voter: PropTypes.object,
    streets: PropTypes.array,
};

const mapStateToProps = (state) => ({
    voter: state.current.voter,
    streets: state.current.streets,
});

export default withRouter(connect(mapStateToProps, { addVoter, getStreets })(AddForm));
