import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Container, Row, Col, Form, Card, Button } from "react-bootstrap";
import Loading from "./Loading";
import { getNth } from "../classes/Lib";
import {
    getStreets,
    exportVoters,
    clearExport,
} from "../redux/actions/voterActions";

class Export extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: {
                W: [],
                d: [],
                STNME: "",
                PTY: [],
                SUPP: false,
                SIGN: false,
                PH_GOOD: false,
            },
            loading: false,
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (
            prevProps.export !== this.props.export &&
            this.props.export !== null
        ) {
            const blob = new Blob([this.props.export.data], {
                type: "application/octet-stream",
            });
            const downloadUrl = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = downloadUrl;
            a.download = this.props.export.filename;
            document.body.appendChild(a);
            a.click();
            this.setState({
                loading: false,
            });
            this.props.clearExport();
        }
    };

    createPartyOptions = () => {
        let pty = [
            "DEM",
            "REP",
            "UNA",
            "CNV",
            "CON",
            "LIB",
            "NAT",
            "GRE",
            "SSP",
            "RFP",
        ];
        let parties = [];
        for (var i = 0; i < pty.length; i++) {
            parties.push(
                <Form.Check
                    key={i}
                    type={"checkbox"}
                    id={"pty-" + i}
                    onChange={this.handleCheck}
                    label={pty[i]}
                    data-label="PTY"
                    data-value={pty[i]}
                    value={pty[i]}
                />
            );
        }
        return parties;
    };

    createStreetOptions = () => {
        let streets = [];
        for (var i = 0; i < this.props.streets.length; i++) {
            streets.push(
                <option value={this.props.streets[i].STNME} key={i}>
                    {this.props.streets[i].STNME}
                </option>
            );
        }
        return streets;
    };

    exportVoters = (e) => {
        this.setState({
            loading: true,
        });
        this.props.exportVoters(this.state.filters);
    };

    handleCheck = (e) => {
        let type = e.target.dataset.label;
        let id = e.target.dataset.value;
        let filters = this.state.filters;
        switch (type) {
            case "SUPP":
            case "SIGN":
            case "PH_GOOD":
                filters[type] = e.target.checked;
                break;
            default:
                let index = filters[type].indexOf(id);
                if (index > -1) {
                    filters[type].splice(index, 1);
                } else {
                    filters[type].push(id);
                }
                this.setState({
                    filters: filters,
                });
                break;
        }
    };

    export = (e) => {};

    render = () => {
        const wards = [];
        const restrictions = this.props.restrictions.split(",");
        for (let i = 1; i <= 10; i++) {
            if (restrictions.length === 0 || restrictions.includes(`${i}`)) {
                wards.push(
                    <Form.Check
                        key={i}
                        type={"checkbox"}
                        onChange={this.handleCheck}
                        id={"ward-" + i}
                        label={`${getNth(i)} Ward`}
                        className="W"
                        data-label="W"
                        data-value={i}
                        value={i}
                    />
                );
            }
        }
        const districts = [];
        for (let i = 1; i <= 5; i++) {
            districts.push(
                <Form.Check
                    key={i}
                    type={"checkbox"}
                    onChange={this.handleCheck}
                    id={"district-" + i}
                    label={`${getNth(i)} District`}
                    className="d"
                    data-label="d"
                    data-value={i}
                    value={i}
                />
            );
        }
        return (
            <Container>
                <Loading display={this.state.loading}></Loading>
                <Card>
                    <Card.Header className="bg-info text-white">
                        Export Voter List
                    </Card.Header>
                    <Card.Body>
                        <p className="mb-2 text-muted">
                            Leave everything blank to export a complete list of
                            the City.
                        </p>
                        <Form>
                            <Row>
                                <Col md={2}>
                                    <Form.Group>
                                        <Form.Label>Select Ward(s)</Form.Label>
                                        <div>{wards}</div>
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group>
                                        <Form.Label>
                                            Select District(s)
                                        </Form.Label>
                                        <div>{districts}</div>
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Label>Street</Form.Label>
                                    <Form.Control
                                        as="select"
                                        id="STNME"
                                        name="STNME"
                                        defaultValue="%"
                                    >
                                        <option value="%">All Street(s)</option>
                                        {this.createStreetOptions()}
                                    </Form.Control>
                                </Col>
                                <Col md={2}>
                                    <Form.Group>
                                        <Form.Label>
                                            Party Affiliation(s)
                                        </Form.Label>
                                        <div>{this.createPartyOptions()}</div>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2}>
                                    <Form.Group>
                                        <Form.Label>
                                            Supporters Only?
                                        </Form.Label>
                                        <Form.Check
                                            type={"checkbox"}
                                            onChange={this.handleCheck}
                                            label={"Yes"}
                                            id="SUPP"
                                            data-label="SUPP"
                                            data-value={true}
                                            value={true}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group>
                                        <Form.Label>Signs Only?</Form.Label>
                                        <Form.Check
                                            type={"checkbox"}
                                            onChange={this.handleCheck}
                                            label={"Yes"}
                                            id="SIGN"
                                            data-label="SIGN"
                                            data-value={true}
                                            value={true}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group>
                                        <Form.Label>Phone # Exists</Form.Label>
                                        <Form.Check
                                            type={"checkbox"}
                                            onChange={this.handleCheck}
                                            label={"Yes"}
                                            id="PH_GOOD"
                                            data-label="PH_GOOD"
                                            data-value={true}
                                            value={true}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Button
                                variant={"success"}
                                className="float-right"
                                onClick={this.exportVoters}
                            >
                                Export
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        );
    };
}

const mapStateToProps = (state) => ({
    streets: state.current.streets,
    export: state.list.export,
    restrictions: state.auth.restrictions,
});

Export.propTypes = {
    exportVoters: PropTypes.func.isRequired,
    export: PropTypes.object,
};

export default connect(mapStateToProps, {
    getStreets,
    exportVoters,
    clearExport,
})(Export);
