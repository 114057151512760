import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch } from 'react-router-dom';
import { checkAuth } from '../redux/actions/userActions';
import PropTypes from 'prop-types';
import Voter from './Voter';
import Stats from './Stats';
import Export from './Export';
import Users from './Users';
import Settings from './Settings';
import DashNavbar from './DashNavbar';
import VoterTable from './VoterTable';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        if (this.props.token !== null || this.props.cookies.get('token')) {
            this.props.checkAuth();
        } else {
            this.props.history.push('/');
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(this.props.token === null) {
            this.props.history.push('/');
        }
    }

    render() {
        return (
            <div>
                <DashNavbar cookies={this.props.cookies} />
                {/* <Toolbar /> */}
                <Switch>
                    <Route exact path='/dashboard'>
                        <VoterTable />
                    </Route>
                    <Route path={`/dashboard/stats`}>
                        <Stats />
                    </Route>
                    <Route path={`/dashboard/export`}>
                        <Export />
                    </Route>
                    <Route path={`/dashboard/users`}>
                        <Users />
                    </Route>
                    <Route path={`/dashboard/voter/:id`}>
                        <Voter />
                    </Route>
                    <Route path={`/dashboard/settings`}>
                        <Settings />
                    </Route>
                </Switch>
            </div>
        );
    }
}

Dashboard.propTypes = {
    token: PropTypes.string,
    checkAuth: PropTypes.func.isRequired,
    message: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
    return {
        token: state.auth.token,
        message: state.auth.message,
        cookies: ownProps.cookies
    };
};

export default withRouter(connect(mapStateToProps, { checkAuth })(Dashboard));
