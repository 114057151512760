import { GET_VOTERS, NOT_FOUND, GET_USERS, EXPORT_VOTERS } from '../actions/types';

const initialState = {
    voters: [],
    filters: {
        FULLNME: '',
        STNME: '%',
        PTY: '%',
        ADDR: '',
        W: '%',
        SUPP: '%',
        PH: '',
        d: '%',
        SIGN: '%',
    },
    message: '',
    export: null,
    users: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_VOTERS:
            return {
                ...state,
                voters: action.voters,
                filters: action.filters ?? state.filters,
            };
        case NOT_FOUND:
            return {
                ...state,
                voters: [],
                message: action.message,
            };
        case GET_USERS:
            return {
                ...state,
                users: action.users,
            };
        case EXPORT_VOTERS:
            return {
                ...state,
                export: action.export,
            };
        default:
            return state;
    }
};
