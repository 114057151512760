import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import EditForm from './EditForm';
import AddForm from './AddForm';

class Voter extends React.Component {
    render() {
        if(this.props.match.params.id === 'add') {
            return (
                <div>
                    <AddForm />
                </div>
            );
        } else {
            return (
                <div>
                    <EditForm voterid={this.props.match.params.id} />
                </div>
            );
        }
    }
}

Voter.propTypes = {
    user: PropTypes.object,
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

export default withRouter(connect(mapStateToProps, null)(Voter));
