import { GET_USERS, INVALID_REQUEST, ADMIN_DELETE_USER, ADMIN_UPDATE_USER, ADMIN_LOCK_USER, ADMIN_UNLOCK_USER, ADMIN_REQUEST_PASSWORD, ADMIN_ADD_USER } from './types';

import axios from 'axios';

const transport = axios.create({
    withCredentials: true,
});

export const getUsers = () => (dispatch) => {
    transport
        .get(process.env.REACT_APP_API_URL + '/admin/users')
        .then((res) => {
            dispatch({
                type: GET_USERS,
                users: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: INVALID_REQUEST,
                message: err.data ? err.data.sqlMessage ?? err.data.message ?? 'An error has occurred' : 'An error has occurred',
            });
        });
};

export const deleteUser = (id) => (dispatch) => {
    transport
        .post(process.env.REACT_APP_API_URL + '/admin/user/delete/', { id: id })
        .then((res) => {
            dispatch({
                type: ADMIN_DELETE_USER,
                message: res.data.message,
            });
            dispatch(getUsers());
        })
        .catch((err) => {
            if (err.response) {
                dispatch({
                    type: INVALID_REQUEST,
                    message: err.response.data.sqlMessage ? err.response.data.sqlMessage : err.response.data.message ? err.response.data.message : 'An error has occurred.',
                });
            }
        });
};

export const lockUser = (id) => (dispatch) => {
    transport
        .post(process.env.REACT_APP_API_URL + '/admin/user/lock/' + id)
        .then((res) => {
            dispatch({
                type: ADMIN_LOCK_USER,
                message: res.data.message,
            });
            dispatch(getUsers());
        })
        .catch((err) => {
            if (err.response) {
                dispatch({
                    type: INVALID_REQUEST,
                    message: err.response.data.sqlMessage ? err.response.data.sqlMessage : err.response.data.message ? err.response.data.message : 'An error has occurred.',
                });
            }
        });
}

export const unlockUser = (id) => (dispatch) => {
    transport
        .post(process.env.REACT_APP_API_URL + '/admin/user/unlock/' + id)
        .then((res) => {
            dispatch({
                type: ADMIN_UNLOCK_USER,
                message: res.data.message,
            });
            dispatch(getUsers());
        })
        .catch((err) => {
            if (err.response) {
                dispatch({
                    type: INVALID_REQUEST,
                    message: err.response.data.sqlMessage ? err.response.data.sqlMessage : err.response.data.message ? err.response.data.message : 'An error has occurred.',
                });
            }
        });
}

export const updateUser = (user) => (dispatch) => {
    transport
        .post(process.env.REACT_APP_API_URL + '/admin/user/update/', { user: user })
        .then((res) => {
            dispatch({
                type: ADMIN_UPDATE_USER,
                message: res.data.message,
            });
        })
        .catch((err) => {
            if (err.response) {
                dispatch({
                    type: INVALID_REQUEST,
                    message: err.response.data.sqlMessage ? err.response.data.sqlMessage : err.response.data.message ? err.response.data.message : 'An error has occurred.',
                });
            }
        });
}

export const requestNewPassword = (email) => (dispatch) => {
    transport
        .post(process.env.REACT_APP_API_URL + '/admin/user/reset/', { email: email })
        .then((res) => {
            dispatch({
                type: ADMIN_REQUEST_PASSWORD,
                message: res.data.message,
            });
        })
        .catch((err) => {
            if (err.response) {
                dispatch({
                    type: INVALID_REQUEST,
                    message: err.response.data.sqlMessage ? err.response.data.sqlMessage : err.response.data.message ? err.response.data.message : 'An error has occurred.',
                });
            }
        });
}

export const addUser = (user) => (dispatch) => {
    dispatch({
        type: ADMIN_ADD_USER,
        message: '',
        userAdded: false,
    });
    transport
        .post(process.env.REACT_APP_API_URL + '/admin/user/add', {user: user})
        .then((res) => {
            dispatch({
                type: ADMIN_ADD_USER,
                message: res.data.message,
                userAdded: true,
            });
            dispatch(getUsers());
        })
        .catch((err) => {
            if (err.response) {
                dispatch({
                    type: INVALID_REQUEST,
                    message: err.response.data.sqlMessage ? err.response.data.sqlMessage : err.response.data.message ? err.response.data.message : 'An error has occurred.',
                });
            }
        })
}
