import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logout } from '../redux/actions/userActions';
import PropTypes from 'prop-types';
import { Navbar, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class DashNavbar extends Component {
    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            coll: true,
        };

        this.logout = this.logout.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.user === null) {
            this.props.history.push('/');
        }
    }

    logout = () => {
        this.props.cookies.remove('token');
        this.props.logout();
    };

    toggleNavbar = () => {
        this.setState({
            coll: !this.state.coll,
        });
    };

    render() {
        return (
            <Navbar
                collapseOnSelect
                bg="primary"
                variant="dark"
                fixed="top"
                expand="lg"
            >
                <Navbar.Brand href="/dashboard">SurvElect</Navbar.Brand>
                <Navbar.Toggle onClick={this.toggleNavbar} className="mr-2" />
                <Navbar.Collapse>
                    <Nav className="ml-auto">
                        <Nav.Link href="/dashboard/">
                            Voters{' '}
                            <FontAwesomeIcon icon={['far', 'person-booth']} />
                        </Nav.Link>
                        <Nav.Link href="/dashboard/stats">
                            Statistics{' '}
                            <FontAwesomeIcon icon={['far', 'user-chart']} />
                        </Nav.Link>
                        <Nav.Link href="/dashboard/settings">
                            Account{' '}
                            <FontAwesomeIcon icon={['far', 'user-cog']} />
                        </Nav.Link>
                        <Nav.Link href="/dashboard/users" style={{ display: this.props.admin ? 'block' : 'none'}}>
                            Users{' '}
                            <FontAwesomeIcon icon={['far', 'users']} />
                        </Nav.Link>
                        <Nav.Link href="/dashboard/voter/add">
                            Add Voter{' '}
                            <FontAwesomeIcon icon={['far', 'user-plus']} />
                        </Nav.Link>
                        <Nav.Link onClick={this.logout}>
                            Logout{' '}
                            <FontAwesomeIcon icon={['far', 'sign-out-alt']} />
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

DashNavbar.propTypes = {
    user: PropTypes.object,
    logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
    admin: state.auth.admin,
});

export default withRouter(connect(mapStateToProps, { logout })(DashNavbar));
