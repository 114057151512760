import { ADMIN_ADD_USER } from '../actions/types';

const initialState = {
    message: '',
    userAdded: false,
    refresh: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ADMIN_ADD_USER:
            return {
                ...state,
                message: action.message ?? state.message,
                userAdded: action.userAdded ?? state.userAdded,
            };
        default:
            return {
                ...state,
                message: action.message ?? state.message,
            };
    }
};
