import { GET_VOTERS, EXPORT_VOTERS, NOT_FOUND, GET_VOTER, GET_STREETS, UPDATE_VOTER, DELETE_VOTER } from './types';

import axios from 'axios';

const transport = axios.create({
    withCredentials: true,
});

export const getVoters = (filters = {}) => (dispatch) => {
    let url = process.env.REACT_APP_API_URL + '/voters';
    if (filters.W && filters.W !== '%') {
        url += `/${filters.W}`;
    }
    if (filters.d && filters.d !== '%') {
        url += `/${filters.d}`;
    }
    let query = '';
    let arr = Object.keys(filters);
    arr.forEach((key, index) => {
        if (key !== 'W' && key !== 'd') {
            if (filters[key].length > 0 && filters[key] !== '%') {
                query += `${key}=${encodeURIComponent(filters[key])}&`;
            }
        }
    });
    if (query.length > 0) {
        url += `?${query.slice(0, -1)}`;
    }
    // console.log(url);
    transport
        .get(url)
        .then((res) => {
            dispatch({
                type: GET_VOTERS,
                voters: res.data,
                filters: filters,
            });
        })
        .catch((err) => {
            if (err.response) {
                dispatch({
                    type: NOT_FOUND,
                    message: err.response.data.message,
                });
            }
        });
};

export const getVoter = (id) => (dispatch) => {
    transport
        .get(process.env.REACT_APP_API_URL + '/voter/' + id)
        .then((res) => {
            dispatch({
                type: GET_VOTER,
                voter: res.data,
            });
        })
        .catch((err) => {
            if (err.response) {
                console.log(err.response);
                dispatch({
                    type: NOT_FOUND,
                    message: err.response.data.message,
                });
            }
        });
};

export const getStreets = () => (dispatch) => {
    transport
        .get(process.env.REACT_APP_API_URL + '/streets')
        .then((res) => {
            dispatch({
                type: GET_STREETS,
                streets: res.data,
            });
        })
        .catch((err) => {
            if (err.response) {
                console.log(err.response);
                dispatch({
                    type: NOT_FOUND,
                    message: err.response.data.message,
                });
            }
        });
};

export const updateVoter = (voter) => (dispatch) => {
    console.log('Updating Voter');
    console.log(voter);
    transport
        .post(process.env.REACT_APP_API_URL + '/voter/update', { voter })
        .then((res) => {
            dispatch({
                type: UPDATE_VOTER,
                voter: res.data,
                message: `Successfully updated voter ${voter.FSTNME} ${voter.LSTNME}...`,
            });
        })
        .catch((err) => {
            if (err.response) {
                console.log(err.response);
                dispatch({
                    type: NOT_FOUND,
                    message: err.response.data.message,
                });
            }
        });
};

export const addVoter = (voter) => (dispatch) => {
    console.log('Updating Voter');
    console.log(voter);
    transport
        .post(process.env.REACT_APP_API_URL + '/voter/add', { voter })
        .then((res) => {
            dispatch({
                type: UPDATE_VOTER,
                voter: res.data,
                message: `Successfully updated voter ${voter.FSTNME} ${voter.LSTNME}...`,
            });
        })
        .catch((err) => {
            if (err.response) {
                console.log(err.response);
                dispatch({
                    type: NOT_FOUND,
                    message: err.response.data.message,
                });
            }
        });
};

export const deleteVoter = (voter, type, reason) => (dispatch) => {
    console.log('Deleting Voter');
    console.log(voter.VOTERID);
    console.log('TYPE: ' + type);
    console.log('REASON: ' + reason);
    transport
        .post(process.env.REACT_APP_API_URL + '/voter/delete', { id: voter.VOTERID, type: type, reason: reason })
        .then((res) => {
            dispatch({
                type: DELETE_VOTER,
                deleted: res.data,
                message: `Request submitted to delete ${voter.FSTNME} ${voter.LSTNME}...`,
            });
        })
        .catch((err) => {
            if (err.response) {
                console.log(err.response);
                dispatch({
                    type: NOT_FOUND,
                    message: err.response.data.message,
                });
            }
        });
};

export const exportVoters = (exportFilters) => (dispatch) => {
    let filters = JSON.parse(JSON.stringify(exportFilters));
    if (filters.W.length === 0) {
        delete filters.W;
    }
    if (filters.d.length === 0) {
        delete filters.d;
    }
    if (filters.PTY.length === 0) {
        delete filters.PTY;
    }
    transport
        .post(process.env.REACT_APP_API_URL + '/export', { filters: filters })
        .then((res) => {
            dispatch({
                type: EXPORT_VOTERS,
                export: res.data
            });
        })
        .catch((err) => {
            if (err.response) {
                console.log(err.response);
                dispatch({
                    type: NOT_FOUND,
                    message: err.response.data.message,
                });
            }
        });
};

export const clearExport = () => (dispatch) => {
    dispatch({
        type: EXPORT_VOTERS,
        export: null
    });
};