import { GET_STATS, NOT_FOUND } from './types';

import axios from 'axios';

const transport = axios.create({
    withCredentials: true,
});

export const getStats = () => (dispatch) => {
    transport
        .get(process.env.REACT_APP_API_URL + '/stats')
        .then((res) => {
            dispatch({
                type: GET_STATS,
                data: res.data
            });
        })
        .catch((err) => {
            dispatch({
                type: NOT_FOUND
            })
        });
};