import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {requestNewPassword} from '../redux/actions/userActions';
import { Container, Jumbotron, Row, Col, Form, Button } from 'react-bootstrap';

class Forgot extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: ''
        };

        if (this.props.token !== null || this.props.cookies.get('token')) {
            this.props.history.push('/dashboard');
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevProps.counter !== this.props.counter) {
            if(this.props.requested) {
                this.props.history.push('/');
            }
        }
    }

    handleChange = (e) => {
        this.setState({
            email: e.target.value
        });
    }

    submitRequest = () => {
        this.props.requestNewPassword(this.state.email);
    }

    render = () => {
        return (
            <Container>
                <Jumbotron>
                    <h1>SurvElect</h1>
                </Jumbotron>
                <Row>
                    <Col md={4}>
                        <h3>Forgot Password</h3>
                        <p>In order to protect your account, we do not store passwords.</p>
                        <p>If you forgot your password, we'll send a secure link to the email associated with your account that will expire in 5 minutes.</p>
                        <p>You can click this link to create a new password.</p>
                        <Form>
                            <Form.Group>
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control type="text" placeholder="Email Address" onChange={this.handleChange} onInput={this.handleChange}></Form.Control>
                            </Form.Group>
                            <Button type="button" onClick={this.submitRequest} variant="primary">Submit</Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        id: state.auth.id,
        token: state.auth.token,
        message: state.auth.message,
        requested: state.auth.requested,
        counter: state.auth.counter
    };
};

export default withRouter(connect(mapStateToProps, { requestNewPassword })(Forgot));
