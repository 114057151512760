import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";
import { getStats } from "../redux/actions/statsActions";
import { Chart } from "chart.js";
import "chartjs-plugin-datalabels";
import "chartjs-plugin-piechart-outlabels";
import moment from "moment";
import "countdown";
import "moment-countdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const backgroundColors = [
    "#8f0000",
    "#eb7900",
    "#03befc",
    "#ebc400",
    "#89eb00",
    "#008709",
    "#00877a",
    "#009bd4",
    "#0071d4",
    "#4e00d4",
    "#2700d4",
    "#bf00d4",
    "#d4008d",
    "#d40020",
];

class Stats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            electionDay: "06/06/2023",
            registrationDeadline: "05/16/2023",
            absenteeDeadline: "05/30/2023",
            countdown: {
                electionDay: "Loading...",
                registrationDeadline: "Loading...",
                absenteeDeadline: "Loading...",
            },
            electionDayClass: "",
            registrationDeadlineClass: "",
            absenteeDeadlineClass: "",
        };
        this.shuffleArray(backgroundColors);
        this.SupportersByWardRef = createRef();
        this.SupportersRef = createRef();
        this.props.getStats();
        this.startTimer();
    }

    /* Randomize array in-place using Durstenfeld shuffle algorithm */
    shuffleArray = (array) => {
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
    };

    startTimer = () => {
        setInterval(() => {
            // let electionDay = moment(this.state.electionDay);
            // let registrationDeadline = moment(this.state.registrationDeadline);
            // let absenteeDeadline = moment(this.state.absenteeDeadline);
            let electionDay = moment(this.props.data.ElectionDay);
            let registrationDeadline = moment(this.props.data.RegistrationDate);
            let absenteeDeadline = moment(this.props.data.AbsenteeDate);
            this.setState({
                countdown: {
                    electionDay: electionDay.isAfter(moment())
                        ? electionDay.countdown().toString()
                        : electionDay.fromNow(),
                    registrationDeadline: registrationDeadline.isAfter(moment())
                        ? registrationDeadline.countdown().toString()
                        : registrationDeadline.fromNow(),
                    absenteeDeadline: absenteeDeadline.isAfter(moment())
                        ? absenteeDeadline.countdown().toString()
                        : absenteeDeadline.fromNow(),
                },
                electionDayClass: electionDay.isAfter(moment())
                    ? ""
                    : "text-danger font-weight-bold strike",
                registrationDeadlineClass: registrationDeadline.isAfter(
                    moment()
                )
                    ? ""
                    : "text-danger font-weight-bold strike",
                absenteeDeadlineClass: absenteeDeadline.isAfter(moment())
                    ? ""
                    : "text-danger font-weight-bold strike",
            });
        }, 1000);
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.data !== this.props.data) {
            console.log(this.props.data);
            // eslint-disable-next-line
            let pieChart = new Chart(this.SupportersRef.current, {
                type: "doughnut",
                data: {
                    labels: [
                        "Supporters",
                        "Non-Supporters",
                        "Unidentified / Unsure",
                    ],
                    datasets: [
                        {
                            backgroundColor: ["#00bf49", "#bf0a00", "#db770b"],
                            data: [
                                this.props.data.Supporters,
                                this.props.data.NonSupporters,
                                this.props.data.Voters -
                                    this.props.data.Supporters -
                                    this.props.data.NonSupporters,
                            ],
                        },
                    ],
                },
                options: {
                    legend: {
                        labels: {
                            fontSize: 16,
                            fontStyle: "bold",
                            fontColor: "#000",
                        },
                    },
                    tooltips: {
                        callbacks: {
                            label: (tooltipItem, data) => {
                                console.log(data.datasets[0]);
                                let label = data.labels[tooltipItem.index];
                                let value =
                                    data.datasets[0].data[tooltipItem.index];
                                console.log(label);
                                let percentage = Math.round(
                                    (value / this.props.data.Voters) * 100
                                );
                                if (label) {
                                    label += `: ${value} (${percentage}%)`;
                                }

                                return label;
                            },
                        },
                    },
                    plugins: {
                        datalabels: {
                            display: false,
                        },
                        outlabels: {
                            display: false,
                        },
                    },
                },
            });
            let wards = [];
            let districts = {};
            Object.keys(this.props.data.SupportersByWard).forEach((key) => {
                let ward = this.props.data.SupportersByWard[key];
                wards.push(ward.label);
                Object.keys(ward.districts).forEach((k, index) => {
                    let district = ward.districts[k];
                    districts[district.id] = districts[district.id] ?? {
                        label: district.label,
                        data: [],
                        backgroundColor: "#fff",
                    };
                    while (
                        districts[district.id].data.length <
                        wards.length - 1
                    ) {
                        districts[district.id].data.push(0);
                    }
                    districts[district.id].data.push(district.supporters);
                    districts[district.id].backgroundColor =
                        backgroundColors[index];
                });
            });
            let d = [];
            Object.keys(districts).forEach((key) => {
                d.push(districts[key]);
            });
            // eslint-disable-next-line
            let barChart = new Chart(this.SupportersByWardRef.current, {
                type: "bar",
                data: {
                    labels: wards,
                    datasets: d,
                },
                options: {
                    responsive: true,
                    aspectRatio: 0.5,
                    maintainAspectRatio: false,
                    legend: {
                        labels: {
                            fontSize: 16,
                            fontStyle: "bold",
                            fontColor: "#000",
                        },
                    },
                    scales: {
                        xAxes: [
                            {
                                stacked: true,
                                ticks: {
                                    fontSize: 14,
                                },
                            },
                        ],
                        yAxes: [
                            {
                                stacked: true,
                                ticks: {
                                    fontSize: 14,
                                    beginAtZero: true,
                                },
                            },
                        ],
                    },
                    plugins: {
                        datalabels: {
                            display: false,
                        },
                    },
                },
            });
        }
    };

    getNth = (i) => {
        let mod = i % 10;
        let hundo = i % 100;
        if (hundo > 10 && hundo < 20) {
            mod = 0;
        }
        switch (mod) {
            case 1:
                return `${i}st`;
            case 2:
                return `${i}nd`;
            case 3:
                return `${i}rd`;
            default:
                return `${i}th`;
        }
    };

    render = () => {
        let percentage = Math.round(
            ((this.props.data.Supporters + this.props.data.NonSupporters) /
                this.props.data.Voters) *
                100
        );
        return (
            <Container fluid className="px-5">
                <Row>
                    <Col lg={6} className="mb-3">
                        <Card>
                            <Card.Header className="bg-info text-white">
                                <FontAwesomeIcon icon={["far", "vote-yea"]} />{" "}
                                Supporters
                            </Card.Header>
                            <Card.Body>
                                <canvas ref={this.SupportersRef}></canvas>
                            </Card.Body>
                            <Card.Footer className="bg-dark text-white">
                                <span className="font-weight-bold">
                                    <FontAwesomeIcon
                                        icon={["far", "person-booth"]}
                                    />{" "}
                                    Total Registered Voters:
                                </span>{" "}
                                {this.props.data.Voters}{" "}
                                <FontAwesomeIcon
                                    icon={["far", "grip-lines-vertical"]}
                                />{" "}
                                <span className="font-weight-bold">
                                    <FontAwesomeIcon
                                        icon={["far", "vote-yea"]}
                                    />{" "}
                                    Total Supporters:
                                </span>{" "}
                                {this.props.data.Supporters}
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col lg={6} className="mb-3 d-flex align-items-stretch">
                        <Card>
                            <Card.Header className="bg-info text-white">
                                <FontAwesomeIcon icon={["far", "clock"]} />{" "}
                                Campaign Countdown
                            </Card.Header>
                            <Card.Body>
                                <p className="mt-0 mb-4">
                                    These countdowns will help our team gauge
                                    what we need to push for to win this
                                    election. Interaction percentage is based on
                                    the number of people we've either marked as
                                    supporters or Non-Supporters.
                                </p>
                                <ListGroup>
                                    <ListGroup.Item variant="primary">
                                        <span className="font-weight-bold">
                                            Election Day:
                                        </span>{" "}
                                        {moment(this.props.data.ElectionDay).format(
                                            "dddd, MMMM Do YYYY"
                                        )}
                                    </ListGroup.Item>
                                    <ListGroup.Item
                                        className={this.state.electionDayClass}
                                    >
                                        {this.state.countdown.electionDay}
                                    </ListGroup.Item>
                                    <ListGroup.Item variant="success">
                                        <span className="font-weight-bold">
                                            Registration Deadline:
                                        </span>{" "}
                                        {moment(
                                            this.props.data.RegistrationDate
                                        ).format("dddd, MMMM Do YYYY")}
                                    </ListGroup.Item>
                                    <ListGroup.Item
                                        className={
                                            this.state.registrationDeadlineClass
                                        }
                                    >
                                        {
                                            this.state.countdown
                                                .registrationDeadline
                                        }
                                    </ListGroup.Item>
                                    <ListGroup.Item variant="danger">
                                        <span className="font-weight-bold">
                                            Absentee Ballot Deadline:
                                        </span>{" "}
                                        {moment(
                                            this.props.data.AbsenteeDate
                                        ).format("dddd, MMMM Do YYYY")}
                                    </ListGroup.Item>
                                    <ListGroup.Item
                                        className={
                                            this.state.absenteeDeadlineClass
                                        }
                                    >
                                        {this.state.countdown.absenteeDeadline}
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card.Body>
                            <Card.Footer
                                className={
                                    percentage > 49
                                        ? "bg-success text-white"
                                        : percentage < 25
                                        ? "bg-danger text-white"
                                        : "bg-warning"
                                }
                            >
                                <span className="font-weight-bold">
                                    <FontAwesomeIcon
                                        icon={["far", "chart-pie"]}
                                    />{" "}
                                    Interaction Percentage:
                                </span>{" "}
                                {percentage}% (
                                {percentage > 49
                                    ? "Great"
                                    : percentage < 25
                                    ? "Needs Improvement"
                                    : "OK"}
                                )
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Card>
                            <Card.Header className="bg-info text-white">
                                <FontAwesomeIcon
                                    icon={["far", "map-marked-alt"]}
                                />{" "}
                                Supporters by Ward / District
                            </Card.Header>
                            <Card.Body>
                                <canvas ref={this.SupportersByWardRef}></canvas>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    };
}

const mapStateToProps = (state) => ({
    data: state.stats.data,
});

export default connect(mapStateToProps, { getStats })(Stats);
