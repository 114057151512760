import React from 'react';

class Loading extends React.Component {
    render = () => {
        return (
            <div id="overlay" style={{ display: this.props.display ? "block" : "none" }}>
                <div className="loader">
                </div>
            </div>
        );
    }
}

export default Loading;