import { GET_VOTER, NOT_FOUND, GET_STREETS, UPDATE_VOTER, DELETE_VOTER } from '../actions/types';

const initialState = {
    voter: null,
    message: '',
    updated: null,
    streets: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_VOTER:
            return {
                ...state,
                updated: null,
                voter: action.voter,
                message: null,
            };
        case GET_STREETS:
            return {
                ...state,
                streets: action.streets,
            };
        case UPDATE_VOTER:
            return {
                ...state,
                voter: action.voter,
                updated: true,
                message: action.message,
            };
        case DELETE_VOTER:
            return {
                ...state,
                updated: true,
                message: action.message,
            };
        case NOT_FOUND:
            return {
                ...state,
                voter: null,
                updated: false,
                message: action.message,
            };
        default:
            return state;
    }
};
