export const getNth = (i) => {
    let mod = i % 10;
    let hundo = i % 100;
    if (hundo > 10 && hundo < 20) {
        mod = 0;
    }
    switch (mod) {
        case 1:
            return `${i}st`;
        case 2:
            return `${i}nd`;
        case 3:
            return `${i}rd`;
        default:
            return `${i}th`;
    }
};
