import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Jumbotron, Row, Col, Form, Button, ProgressBar } from 'react-bootstrap';
import { verifyReset, resetPassword } from '../redux/actions/userActions';
import passwordStrength from 'check-password-strength';

class Reset extends Component {
    constructor(props) {
        super(props);

        this.props.verifyReset(this.props.match.params.id);

        this.state = {
            passwords: {
                newPassword: '',
                confirmPassword: '',
            },
            passwordStrength: {
                value: '',
                length: 0
            }
        };

        if (this.props.token !== null || this.props.cookies.get('token')) {
            this.props.history.push('/dashboard');
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.counter !== this.props.counter) {
            if (!this.props.allowReset) {
                this.props.history.push('/dashboard');
            }
            if(this.props.updated) {
                this.props.history.push('/');
            }
        }
    };

    handleChange = (e) => {
        let passwords = this.state.passwords;
        passwords[e.target.id] = e.target.value;
        let strength = this.state.passwordStrength;
        if(passwords.newPassword.length > 0) {
            strength = passwordStrength(passwords.newPassword);
        } else {
            strength = {
                value: '',
                length: 0
            };
        }

        this.setState({
            passwords: passwords,
            passwordStrength: strength
        });
    };

    updatePassword = () => {
        this.props.resetPassword(this.props.match.params.id, this.state.passwords.newPassword);
    }

    render = () => {
        return (
            <Container>
                <Jumbotron>
                    <h1>SurvElect</h1>
                </Jumbotron>
                <Row>
                    <Col md={4}>
                        <h3>Reset Password</h3>
                        <p>This link will expire soon.</p>
                        <Form>
                            <Form.Group>
                                <Form.Label>New Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="New Password"
                                    autoComplete="new-password"
                                    id="newPassword"
                                    onChange={this.handleChange}
                                    onInput={this.handleChange}
                                    isValid={this.state.passwords.newPassword.length > 0}
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">Password must be at least medium strength</Form.Control.Feedback>
                                <ProgressBar
                                    className="mt-2"
                                    style={{ display: this.state.passwordStrength.length > 0 ? 'flex' : 'none' }}
                                    striped
                                    variant={this.state.passwordStrength.value === 'Weak' ? 'danger' : this.state.passwordStrength.value === 'Medium' ? 'warning' : 'success'}
                                    now={
                                        this.state.passwordStrength.length === 0
                                            ? 0
                                            : this.state.passwordStrength.value === 'Weak'
                                            ? 33
                                            : this.state.passwordStrength.value === 'Medium'
                                            ? 66
                                            : 100
                                    }
                                />
                                <small>{this.state.passwordStrength.value}</small>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Confirm Password"
                                    autoComplete="new-password"
                                    id="confirmPassword"
                                    onChange={this.handleChange}
                                    onInput={this.handleChange}
                                    isValid={this.state.passwords.confirmPassword.length > 0 && this.state.passwords.newPassword === this.state.passwords.confirmPassword}
                                    isInvalid={this.state.passwords.newPassword !== this.state.passwords.confirmPassword}
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">Passwords must match!</Form.Control.Feedback>
                            </Form.Group>
                            <Button type="button" onClick={this.updatePassword} variant="primary">
                                Submit
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        message: state.auth.message,
        allowReset: state.auth.allowReset,
        counter: state.auth.counter,
        updated: state.auth.updated
    };
};

export default withRouter(connect(mapStateToProps, { verifyReset, resetPassword })(Reset));
