import React from 'react';
import {
    Container,
    Jumbotron,
    FormGroup,
    FormControl,
    Button,
    Alert,
    Form,
} from 'react-bootstrap';
import User from '../classes/User';
import { connect } from 'react-redux';
import { authUser, checkAuth } from '../redux/actions/userActions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            message: this.props.message ?? '',
            hideAlert: this.props.message ?? true
        };

        if (this.props.token !== null || this.props.cookies.get('token')) {
            this.props.history.push('/dashboard');
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(this.props.message !== prevProps.message) {
            console.log(this.props.message);
            if(this.props.token === null) {
                this.setState({ hideAlert: false, message: this.props.message });
            } else {
                this.setState({ hideAlert: true });
            }
        }
        if(this.props.token !== null) {
            this.props.history.push('/dashboard');
        }
    }

    handleChange = (e) => {
        let obj = {};
        obj[e.target.id] = e.target.value;
        this.setState(obj);
    };

    authenticate = () => {
        this.props.authUser(new User(this.state.email, this.state.password));
    };

    render() {
        return (
            <div>
                <Container>
                    <Jumbotron>
                        <h1>SurvElect</h1>
                    </Jumbotron>
                    <h2>Log in</h2>
                    <Alert variant="warning" hidden={this.state.hideAlert}>{this.state.message}</Alert>
                    <Form>
                        <FormGroup>
                            <Form.Label htmlFor="id">Email Address</Form.Label>
                            <Form.Control
                                type="email"
                                id="email"
                                aria-describedby="idHelp"
                                name="email"
                                placeholder="Email Address"
                                onChange={this.handleChange}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') this.authenticate();
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Form.Label htmlFor="password">Password</Form.Label>
                            <FormControl
                                type="password"
                                id="password"
                                name="password"
                                placeholder="Password"
                                onChange={this.handleChange}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') this.authenticate();
                                }}
                            />
                            <small><a href="/forgot">Forgot password?</a></small>
                        </FormGroup>
                        <Button variant="primary" onClick={this.authenticate}>
                            Log in
                        </Button>
                    </Form>
                    <hr />
                    <Alert variant="danger">
                        <Alert.Heading>Warning!</Alert.Heading>
                        <p>
                            The following portal is restricted from unauthorized
                            users. Your IP Address has been stored in our
                            database. Any unauthorized attempt to access this
                            portal will be reported to the authorities and will
                            be subject to prosecution. (
                            <a href="https://www.uspto.gov/learning-and-resources/ip-policy/database-protection-and-access-issues-recommendations">
                                Database Protection And Access Issues,
                                Recommendation
                            </a>
                            )
                        </p>
                        <hr />
                        <p className="mb-0">
                            If you are not authorized to view this page,{' '}
                            <a href="http://google.com/">leave now</a>.
                        </p>
                    </Alert>
                </Container>
            </div>
        );
    }
}

Login.propTypes = {
    authUser: PropTypes.func.isRequired,
    id: PropTypes.string,
    token: PropTypes.string,
    message: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        id: state.auth.id,
        token: state.auth.token,
        message: state.auth.message,
    };
};

export default withRouter(connect(mapStateToProps, { authUser, checkAuth })(Login));
