import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Card, Row, Col, Form, Button, ProgressBar, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import passwordStrength from 'check-password-strength';
import { updateUser } from '../redux/actions/userActions';

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                firstName: this.props.user.firstName,
                lastName: this.props.user.lastName,
                email: this.props.email,
                password: '',
                newPassword: '',
                confirmPassword: '',
                newEmail: this.props.email,
            },
            passwordStarted: false,
            emailCheck: true,
            passwordStrength: {
                value: '',
                length: 0,
            },
            passwordsMatch: false,
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.passwordStrength !== this.state.passwordStrength) {
            console.log(this.state.passwordStrength);
        }
        if (prevProps.updated !== this.props.updated || prevProps.error !== this.props.error) {
            console.log(this.props.updated);
            if (this.props.updated) {
                let user = this.state.user;
                user.password = '';
                user.newPassword = '';
                user.confirmPassword = '';
                this.setState({
                    user: user,
                    passwordStrength: {
                        value: '',
                        length: 0
                    },
                    passwordStarted: false
                });
            }
        }
    };

    handleChange = (e) => {
        let user = this.state.user;
        user[e.target.id] = e.target.value;
        // console.log(`${e.target.id} => ${e.target.value}`);

        if (e.target.id === 'password') {
            this.setState({ passwordStarted: true });
        }

        let test = this.state.passwordStrength;
        if (user.newPassword.length > 0) {
            test = passwordStrength(user.newPassword);
        } else {
            test.value = '';
            test.length = 0;
        }
        let match = user.newPassword === user.confirmPassword;
        this.setState({
            user: user,
            passwordStrength: test,
            passwordsMatch: match,
            //eslint-disable-next-line
            emailCheck: /([A-Za-z0-9.\-_])+(@)([A-Za-z0-9.\-])+((\.)([A-Za-z])){0,1}(\.)([A-Za-z]){2,24}/g.test(user.email),
        });
    };

    saveChanges = () => {
        this.props.updateUser(this.state.user);
    };

    render = () => {
        return (
            <Container>
                <Alert variant={this.props.updated ? 'success' : 'danger' } style={{ display: this.props.error.length > 0 ? 'block' : 'none' }}>
                    {this.props.error}
                </Alert>
                <Row>
                    <Col md={6}>
                        <Card>
                            <Card.Header className="bg-dark text-white">
                                <FontAwesomeIcon icon={['far', 'user-cog']} /> Account Settings
                            </Card.Header>
                            <Card.Body>
                                <Form noValidate>
                                    <Form.Group>
                                        <Form.Label>
                                            First Name <small className="text-danger">*</small>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={this.state.user.firstName}
                                            isValid={this.state.user.firstName.length > 0}
                                            isInvalid={this.state.user.firstName.length < 1}
                                            id="firstName"
                                            onChange={this.handleChange}
                                            onInput={this.handleChange}
                                        ></Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>
                                            Last Name <small className="text-danger">*</small>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={this.state.user.lastName}
                                            isValid={this.state.user.lastName.length > 0}
                                            isInvalid={this.state.user.lastName.length < 1}
                                            id="lastName"
                                            onChange={this.handleChange}
                                            onInput={this.handleChange}
                                        ></Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>
                                            Email Address <small className="text-danger">*</small>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={this.state.user.newEmail}
                                            id="newEmail"
                                            autoComplete="new-password"
                                            onChange={this.handleChange}
                                            onInput={this.handleChange}
                                            isValid={this.state.emailCheck}
                                            isInvalid={!this.state.emailCheck && this.state.user.email.length > 0}
                                        ></Form.Control>
                                        <Form.Control.Feedback type="invalid">Invalid Email Address</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>
                                            Current Password <small className="text-danger">*</small>
                                        </Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="Password"
                                            isValid={this.state.user.password.length > 0 && this.state.passwordStarted}
                                            isInvalid={this.state.user.password.length < 6 && this.state.passwordStarted}
                                            id="password"
                                            value={this.state.user.password}
                                            autoComplete="new-password"
                                            onInput={this.handleChange}
                                        ></Form.Control>
                                        <Form.Control.Feedback type="invalid">Your current password should be at least 6 characters!</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>
                                            New Password <small className="text-danger">*</small>
                                        </Form.Label>
                                        <Form.Control
                                            type="password"
                                            isValid={this.state.passwordStrength.value !== 'Weak' && this.state.passwordStrength.length > 0}
                                            isInvalid={this.state.passwordStrength.value === 'Weak'}
                                            placeholder="Password"
                                            value={this.state.user.newPassword}
                                            id="newPassword"
                                            autoComplete="new-password"
                                            onInput={this.handleChange}
                                        ></Form.Control>
                                        <Form.Control.Feedback type="invalid">Password must be at least medium strength</Form.Control.Feedback>
                                        <ProgressBar
                                            className="mt-2"
                                            style={{ display: this.state.passwordStrength.length > 0 ? 'flex' : 'none' }}
                                            striped
                                            variant={this.state.passwordStrength.value === 'Weak' ? 'danger' : this.state.passwordStrength.value === 'Medium' ? 'warning' : 'success'}
                                            now={
                                                this.state.passwordStrength.length === 0
                                                    ? 0
                                                    : this.state.passwordStrength.value === 'Weak'
                                                    ? 33
                                                    : this.state.passwordStrength.value === 'Medium'
                                                    ? 66
                                                    : 100
                                            }
                                        />
                                        <small>{this.state.passwordStrength.value}</small>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>
                                            Confirm New Password <small className="text-danger">*</small>
                                        </Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="Confirm New Password"
                                            isValid={this.state.passwordsMatch && this.state.user.confirmPassword.length > 0}
                                            isInvalid={!this.state.passwordsMatch && this.state.user.confirmPassword.length > 0}
                                            id="confirmPassword"
                                            value={this.state.user.confirmPassword}
                                            autoComplete="new-password"
                                            onInput={this.handleChange}
                                        ></Form.Control>
                                        <Form.Control.Feedback type="invalid">Passwords must match!</Form.Control.Feedback>
                                    </Form.Group>
                                    <Button type="button" variant="success" onClick={this.saveChanges}>
                                        <FontAwesomeIcon icon={['far', 'save']} /> Save Changes
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} className="d-flex align-items-stretch">
                        <Card>
                            <Card.Header className="bg-dark text-white">
                                <FontAwesomeIcon icon={['far', 'user-shield']} /> Security
                            </Card.Header>
                            <Card.Body>
                                <h5 className="font-weight-bold">
                                    <FontAwesomeIcon icon={['far', 'lock-alt']} /> Important Security Information:
                                </h5>
                                <ul>
                                    <li>Never give away any information about this account.</li>
                                    <li>This database should never be a topic of conversation with people who are not members of the team.</li>
                                    <li>Giving your account details or any information in this database to an unauthorized person is not allowed and you will be prosecuted if you do.</li>
                                    <li>The information stored in this database is not for commercial or private use. Voters have a right to privacy.</li>
                                    <li>No one on the team will ever ask for your password. Don't be fooled.</li>
                                </ul>
                                <h5 className="font-weight-bold">
                                    <FontAwesomeIcon icon={['far', 'shield-check']} /> Tips for a Strong Password:
                                </h5>
                                <ul>
                                    <li>Make your password at least 8 characters long.</li>
                                    <li>Include at least one uppercase letter.</li>
                                    <li>Include at least one lowercase letter.</li>
                                    <li>Include at least one number / digit.</li>
                                    <li>Include at least one special character (!#@%$).</li>
                                </ul>
                                <h5 className="font-weight-bold">
                                    <FontAwesomeIcon icon={['far', 'question-circle']} /> Have questions or need help?
                                </h5>
                                <p>
                                    You can email the administrator for assistance at: <a href="mailto:admin@survelect.net">admin@survelect.net</a>
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    };
}

const mapStateToProps = (state) => ({
    email: state.auth.email,
    user: state.auth.user,
    error: state.auth.message,
    updated: state.auth.updated,
});

export default connect(mapStateToProps, { updateUser })(Settings);
