import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Jumbotron, Row, Col, Form, Button, ProgressBar } from 'react-bootstrap';
import { verifyCreate, confirmUser } from '../redux/actions/userActions';
import passwordStrength from 'check-password-strength';

class Create extends Component {
    constructor(props) {
        super(props);

        this.props.verifyCreate(this.props.match.params.id);

        this.state = {
            user: {
                ...this.props.user,
                password: '',
                confirmPassword: '',
            },
            passwordStrength: {
                value: '',
                length: 0,
            },
        };

        if (this.props.token !== null || this.props.cookies.get('token')) {
            this.props.history.push('/dashboard');
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.createFlow !== 0) {
            this.props.history.push('/');
        }
        if (prevProps.user !== this.props.user) {
            this.setState({
                user: {
                    ...this.props.user,
                    password: '',
                    confirmPassword: '',
                },
            });
        }
    };

    handleChange = (e) => {
        let user = this.state.user;
        user[e.target.id] = e.target.value;
        let strength = this.state.passwordStrength;
        if (user.password.length > 0) {
            strength = passwordStrength(user.password);
        } else {
            strength = {
                value: '',
                length: 0,
            };
        }
        this.setState({
            user: user,
            passwordStrength: strength,
        });
    };

    addUser = () => {
        this.props.confirmUser(this.props.match.params.id, this.state.user);
    };

    render = () => {
        return (
            <Container>
                <Jumbotron>
                    <h1>SurvElect</h1>
                </Jumbotron>
                <Row>
                    <Col md={4}>
                        <h3>Create your Account</h3>
                        <p>This link will expire soon.</p>
                        <Form>
                            <Form.Group>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    autoComplete="new-password"
                                    id="firstName"
                                    onChange={this.handleChange}
                                    onInput={this.handleChange}
                                    value={this.state.user.firstName}
                                ></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    autoComplete="new-password"
                                    id="lastName"
                                    onChange={this.handleChange}
                                    onInput={this.handleChange}
                                    value={this.state.user.lastName}
                                ></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control type="text" autoComplete="new-password" id="email" onChange={this.handleChange} onInput={this.handleChange} value={this.state.user.email}></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>New Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="New Password"
                                    autoComplete="new-password"
                                    id="password"
                                    onChange={this.handleChange}
                                    onInput={this.handleChange}
                                    isValid={this.state.user.password.length > 0}
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">Password must be at least medium strength</Form.Control.Feedback>
                                <ProgressBar
                                    className="mt-2"
                                    style={{ display: this.state.passwordStrength.length > 0 ? 'flex' : 'none' }}
                                    striped
                                    variant={this.state.passwordStrength.value === 'Weak' ? 'danger' : this.state.passwordStrength.value === 'Medium' ? 'warning' : 'success'}
                                    now={this.state.passwordStrength.length === 0 ? 0 : this.state.passwordStrength.value === 'Weak' ? 33 : this.state.passwordStrength.value === 'Medium' ? 66 : 100}
                                />
                                <small>{this.state.passwordStrength.value}</small>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Confirm Password"
                                    autoComplete="new-password"
                                    id="confirmPassword"
                                    onChange={this.handleChange}
                                    onInput={this.handleChange}
                                    isValid={this.state.user.confirmPassword.length > 0 && this.state.user.password === this.state.user.confirmPassword}
                                    isInvalid={this.state.user.password !== this.state.user.confirmPassword}
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">Passwords must match!</Form.Control.Feedback>
                            </Form.Group>
                            <Button type="button" onClick={this.addUser} variant="primary">
                                Submit
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        message: state.auth.message,
        createFlow: state.auth.createFlow,
        user: state.auth.user,
    };
};

export default withRouter(connect(mapStateToProps, { verifyCreate, confirmUser })(Create));
