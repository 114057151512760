import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Table, Modal, Button, Form, Alert } from 'react-bootstrap';
import { getUsers, lockUser, unlockUser, requestNewPassword, deleteUser, updateUser, addUser } from '../redux/actions/adminActions';
import moment from 'moment';
import { getNth } from '../classes/Lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const confirmTypes = ['Lock User', 'Delete User', 'Reset Password', 'Save Changes', 'Add User', 'Unlock User'];

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            confirm: false,
            selectedUser: {},
            addUser: false,
            confirmType: null,
        };
        this.props.getUsers();
    }

    closeModals = () => {
        this.setState({
            confirm: false,
            show: false,
            addUser: false,
        });
    };

    addUserModal = () => {
        this.setState({
            addUser: !this.state.addUser,
            selectedUser: {},
        });
    };

    confirmChanges = (type) => {
        this.setState({
            confirmType: type,
            confirm: true,
        });
    };

    saveChanges = () => {
        switch (this.state.confirmType) {
            case 0:
                this.props.lockUser(this.state.selectedUser.id);
                break;
            case 1:
                this.props.deleteUser(this.state.selectedUser.id);
                break;
            case 2:
                this.props.requestNewPassword(this.state.selectedUser.email);
                break;
            case 3:
                let user = this.state.selectedUser;
                user.newEmail = user.email;
                this.props.updateUser(user);
                break;
            case 4:
                this.props.addUser(this.state.selectedUser);
                break;
            case 5:
                this.props.unlockUser(this.state.selectedUser.id);
                break;
            default:
                break;
        }
        this.closeModals();
    };

    handleChange = (e) => {
        let user = this.state.selectedUser;
        user[e.target.id] = e.target.value;
        this.setState({ selectedUser: user });
    };

    handleCheck = (e) => {
        let user = this.state.selectedUser;
        if (user.restrictions && user.restrictions.length > 0) {
            let restrictions = user.restrictions.split(',');
            let index = restrictions.indexOf(e.currentTarget.value);
            if (index > -1) {
                restrictions.splice(index, 1);
            } else {
                restrictions.push(e.currentTarget.value);
            }
            user.restrictions = restrictions.join();
        } else {
            user.restrictions = e.currentTarget.value;
        }
        if (user.restrictions === '') {
            user.restrictions = null;
        }
        this.setState({
            selectedUser: user,
        });
    };

    openModal = (id) => {
        this.props.users.forEach((user) => {
            if (user.id === id) {
                this.setState({
                    selectedUser: user,
                    show: true,
                });
            }
        });
    };

    render = () => {
        const wards = [];
        for (let i = 1; i <= 10; i++) {
            wards.push(
                <Form.Check
                    key={i}
                    type={'checkbox'}
                    onChange={this.handleCheck}
                    label={`${getNth(i)} Ward`}
                    checked={this.state.selectedUser.restrictions ? this.state.selectedUser.restrictions.split(',').indexOf(`${i}`) > -1 : false}
                    value={i}
                />
            );
        }
        const users = this.props.users ? (
            this.props.users.map((user) => {
                let className = user.pending === (1 || '1') ? ' deleted' : user.locked === (1 || '1') ? ' locked' : '';
                return (
                    <tr className={`edit${className}`} key={user.id} onClick={() => this.openModal(user.id)}>
                        <td>{user.id}</td>
                        <td>{user.lastName}</td>
                        <td>{user.firstName}</td>
                        <td>{user.email}</td>
                        <td>{user.last_login ? moment(user.last_login).add(moment().utcOffset(), 'm').format('MM/DD/YYYY hh:mm:ss A') : 'N/A'}</td>
                        <td>{user.last_ip ? user.last_ip : 'N/A'}</td>
                        <td>{user.export === (1 || '1') ? 'Yes' : 'No'}</td>
                        <td>{user.restrictions ?? 'Any'}</td>
                        <td>
                            {user.locked === (1 || '1')
                                ? [<FontAwesomeIcon key={0} icon={['far', 'user-lock']} />, ' Locked']
                                : user.pending === (1 || '1')
                                ? [<FontAwesomeIcon key={1} icon={['far', 'user-cog']} />, ' Pending']
                                : [<FontAwesomeIcon key={2} className="text-success" icon={['far', 'user-check']} />, ' Good']}
                        </td>
                    </tr>
                );
            })
        ) : (
            <tr></tr>
        );
        return (
            <Container fluid>
                <Alert variant="success" show={this.props.message.length > 0}>
                    {this.props.message}
                </Alert>
                <Row className="mb-0">
                    <Col>
                        <h4>User Management</h4>
                        <p className="mb-0">This management panel is only available to Administrators. Unauthorized use is prohibited.</p>
                    </Col>
                </Row>
                <hr className="my-2" />
                <Row className="justify-content-end mb-2">
                    <Col md={2}>
                        <Button type="button" variant="success" className="float-right" onClick={this.addUserModal}>
                            Add User <FontAwesomeIcon icon={['far', 'user-plus']} />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="table-responsive">
                            <Table>
                                <thead>
                                    <tr className="bg-dark text-white">
                                        <th scope="col">ID#</th>
                                        <th scope="col">Last Name</th>
                                        <th scope="col">First Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Last Login</th>
                                        <th scope="col">Last IP</th>
                                        <th scope="col">Can Export?</th>
                                        <th scope="col">Wards</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>{users}</tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>

                {/* Modify User */}
                <Modal size="lg" show={this.state.show} onHide={this.closeModals}>
                    <Modal.Header className="bg-primary text-white" closeButton>
                        <Modal.Title>Modify User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" id="lastName" value={this.state.selectedUser.lastName} onInput={this.handleChange} onChange={this.handleChange}></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" id="firstName" value={this.state.selectedUser.firstName} onInput={this.handleChange} onChange={this.handleChange}></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control type="email" id="email" value={this.state.selectedUser.email} onInput={this.handleChange} onChange={this.handleChange}></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Allow Export Capabilities</Form.Label>
                                <Form.Control as="select" id="export" name="export" value={this.state.selectedUser.export} onChange={this.handleChange} disabled={this.state.disableInput}>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Wards</Form.Label>
                                <p className="mb-1">
                                    <small>Select the wards that you want this user to have access to.</small>
                                </p>
                                <div>{wards}</div>
                                <p>
                                    <small>Select none to give them full access.</small>
                                </p>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                this.confirmChanges(0);
                            }}
                            style={{ display: this.state.selectedUser.locked === 0 ? 'inline-block' : 'none' }}
                        >
                            Lock User <FontAwesomeIcon icon={['far', 'user-lock']} />
                        </Button>
                        <Button
                            variant="success"
                            onClick={() => {
                                this.confirmChanges(5);
                            }}
                            style={{ display: this.state.selectedUser.locked === 1 ? 'inline-block' : 'none' }}
                        >
                            Unlock User <FontAwesomeIcon icon={['far', 'user-unlock']} />
                        </Button>
                        <Button
                            variant="danger"
                            onClick={() => {
                                this.confirmChanges(1);
                            }}
                        >
                            Delete User <FontAwesomeIcon icon={['far', 'user-minus']} />
                        </Button>
                        <Button
                            variant="info"
                            onClick={() => {
                                this.confirmChanges(2);
                            }}
                        >
                            Reset Password <FontAwesomeIcon icon={['far', 'user-cog']} />
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                this.confirmChanges(3);
                            }}
                        >
                            Save Changes <FontAwesomeIcon icon={['far', 'user-check']} />
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Add User */}
                <Modal size="lg" show={this.state.addUser} onHide={this.closeModals}>
                    <Modal.Header className="bg-primary text-white" closeButton>
                        <Modal.Title>Add User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>This will send an email to the address specified addres below asking the user to confirm their account details by clicking a secure link. The link will expire in 1 hour.</p>
                        <Form>
                            <Form.Group>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" id="lastName" placeholder="Last Name" autoComplete="new-password" onInput={this.handleChange} onChange={this.handleChange}></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" id="firstName" placeholder="First Name" autoComplete="new-password" onInput={this.handleChange} onChange={this.handleChange}></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control type="email" id="email" placeholder="Email Address" autoComplete="new-password" onInput={this.handleChange} onChange={this.handleChange}></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Allow Export Capabilities</Form.Label>
                                <Form.Control as="select" id="export" name="export" defaultValue="0" onChange={this.handleChange} disabled={this.state.disableInput}>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Wards</Form.Label>
                                <p className="mb-1">
                                    <small>Select the wards that you want this user to have access to.</small>
                                </p>
                                <div>{wards}</div>
                                <p>
                                    <small>Select none to give them full access.</small>
                                </p>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            onClick={() => {
                                this.confirmChanges(4);
                            }}
                        >
                            Add User <FontAwesomeIcon icon={['far', 'user-plus']} />
                        </Button>
                        <Button variant="danger" onClick={this.closeModals}>
                            Cancel <FontAwesomeIcon icon={['far', 'times-octagon']} />
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Confirm Changes */}
                <Modal size="lg" show={this.state.confirm} onHide={this.closeModals}>
                    <Modal.Header className="bg-danger text-white" closeButton>
                        <Modal.Title>Confirm Changes</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Are you sure you want to make the following changes?</h5>
                        <b>{confirmTypes[this.state.confirmType]}:</b> {this.state.selectedUser.firstName} {this.state.selectedUser.lastName}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.saveChanges}>
                            Confirm Changes <FontAwesomeIcon icon={['far', 'check']} />
                        </Button>
                        <Button variant="primary" onClick={this.closeModals}>
                            Cancel Changes <FontAwesomeIcon icon={['far', 'times-octagon']} />
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        );
    };
}

const mapStateToProps = (state) => ({
    users: state.list.users,
    message: state.admin.message,
});

export default connect(mapStateToProps, { getUsers, lockUser, unlockUser, deleteUser, requestNewPassword, updateUser, addUser })(Users);
