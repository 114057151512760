import { GET_STATS, NOT_FOUND } from '../actions/types';

const initialState = {
    data: {
        SupportersByWard: null,
        Supporters: 0,
        Voters: 0,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_STATS:
            return {
                ...state,
                data: action.data,
            };
        case NOT_FOUND:
            return {
                ...state,
            };
        default:
            return state;
    }
};
