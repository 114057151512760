import React from 'react';
import { Form, Container, Table, Button, Modal, Alert, ButtonGroup, InputGroup, ButtonToolbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { getVoter, getStreets, updateVoter, deleteVoter } from '../redux/actions/voterActions';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import 'react-datepicker/dist/react-datepicker.css';

class EditForm extends React.Component {
    constructor(props) {
        super(props);
        this.props.getVoter(this.props.voterid);
        this.props.getStreets();
        this.state = {
            DOB: new Date(),
            REGDTE: new Date(),
            AGE: 0,
            showDelete: false,
            showSubmit: false,
            disableInput: true,
            lockIcon: 'unlock',
            lockButton: 'primary',
            voter: {},
            deleteType: 1,
            deleteReason: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.deleteVoter = this.deleteVoter.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.voter !== this.props.voter) {
            this.setState({
                DOB: new Date(this.props.voter.DOB),
                REGDTE: this.props.voter.REGDTE === '0000-00-00 00:00:00' ? new Date() : new Date(this.props.voter.REGDTE),
                AGE: moment().diff(moment(this.props.voter.DOB), 'years'),
                voter: this.props.voter,
            });
        }
        if (prevProps.updated !== this.props.updated && this.props.updated === true) {
            this.setState({ showDelete: false });
            this.setState({ showSubmit: false });
            this.props.history.push(`/dashboard?last=${this.state.voter.VOTERID}`);
        }
        if (prevProps.streets !== this.props.streets) {
            this.setState({
                streets: this.props.streets,
            });
        }
    }

    createPartyOptions = () => {
        let pty = ['DEM', 'REP', 'UNA', 'CNV', 'CON', 'LIB', 'NAT', 'GRE', 'SSP', 'RFP'];
        let parties = [];
        for (var i = 0; i < pty.length; i++) {
            parties.push(
                <option value={pty[i]} key={i}>
                    {pty[i]}
                </option>
            );
        }
        return parties;
    };

    createStreetOptions = () => {
        let streets = [];
        for (var i = 0; i < this.props.streets.length; i++) {
            streets.push(
                <option value={this.props.streets[i].STNME} key={i}>
                    {this.props.streets[i].STNME}
                </option>
            );
        }
        return streets;
    };

    handleChange(e) {
        let voter = this.state.voter;
        console.log(`${e.target.id} => ${e.target.value}`);
        voter[e.target.id] = e.target.value;
        this.setState({
            voter: voter,
        });
    }

    saveChanges(e) {
        this.props.updateVoter(this.state.voter);
    }

    deleteVoter(e) {
        this.props.deleteVoter(this.state.voter, this.state.deleteType, this.state.deleteReason);
    }

    render() {
        return (
            <div>
                <Container className="mb-3">
                    <ButtonToolbar className="justify-content-between">
                        <InputGroup>
                            <h2>
                                <FontAwesomeIcon icon={['far', 'user-edit']} /> Edit Voter
                            </h2>
                        </InputGroup>
                        <ButtonGroup>
                            <Button
                                variant={this.state.lockButton}
                                onClick={() => {
                                    this.setState({
                                        disableInput: !this.state.disableInput,
                                        lockIcon: this.state.lockIcon === 'unlock' ? 'lock' : 'unlock',
                                        lockButton: this.state.lockButton === 'primary' ? 'secondary' : 'primary',
                                    });
                                }}
                            >
                                {this.state.disableInput ? 'Allow Editing' : 'Disable Editing'} <FontAwesomeIcon icon={['far', this.state.lockIcon]} />
                            </Button>
                        </ButtonGroup>
                    </ButtonToolbar>
                    <hr />
                    <p className="text-muted">
                        Last Updated By {this.state.voter.LAST_UPDATED_BY} {moment(this.state.voter.LAST_UPDATED).add(moment().utcOffset(), 'm').fromNow()}
                    </p>
                    <Form.Row>
                        <Form.Group className="col-md-3">
                            <Form.Label htmlFor="LSTNME">Last Name</Form.Label>
                            <Form.Control type="text" id="LSTNME" name="LSTNME" defaultValue={this.state.voter.LSTNME} onInput={this.handleChange} readOnly disabled />
                        </Form.Group>
                        <Form.Group className="col-md-3">
                            <Form.Label htmlFor="FSTNME">First Name</Form.Label>
                            <Form.Control type="text" id="FSTNME" name="FSTNME" defaultValue={this.state.voter.FSTNME} onInput={this.handleChange} readOnly disabled />
                        </Form.Group>
                        <Form.Group className="col-md-1">
                            <Form.Label htmlFor="MID">Middle</Form.Label>
                            <Form.Control type="text" id="MID" name="MID" defaultValue={this.state.voter.MID || ''} onInput={this.handleChange} readOnly disabled />
                        </Form.Group>
                        <Form.Group className="col-md-2 divToggle">
                            <Form.Label htmlFor="ETH">Ethnicity</Form.Label>
                            <Form.Control
                                type="text"
                                id="ETH"
                                name="ETH"
                                defaultValue={this.state.voter.ETH || ''}
                                readOnly={this.state.disableInput}
                                disabled={this.state.disableInput}
                                onInput={this.handleChange}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className="col-md-2">
                            <Form.Label htmlFor="DOB">DOB</Form.Label>
                            <DatePicker selected={this.state.DOB} className="form-control" readOnly disabled />
                        </Form.Group>
                        <Form.Group className="col-md-1">
                            <Form.Label htmlFor="AGE">Age</Form.Label>
                            <Form.Control type="number" id="AGE" name="AGE" value={this.state.AGE} readOnly disabled />
                        </Form.Group>
                        <Form.Group className="col-md-2">
                            <Form.Label htmlFor="REGDTE">Registered</Form.Label>
                            <DatePicker selected={this.state.REGDTE} className="form-control" onInput={this.handleChange} readOnly disabled />
                        </Form.Group>
                        <Form.Group className="col-md-2 divToggle">
                            <Form.Label htmlFor="PTY">Party Affiliation</Form.Label>
                            <Form.Control
                                as="select"
                                id="PTY"
                                name="PTY"
                                value={this.state.voter.PTY}
                                onChange={this.handleChange}
                                readOnly={this.state.disableInput}
                                disabled={this.state.disableInput}
                            >
                                {this.createPartyOptions()}
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        {this.state.voter.PH && this.state.voter.PH_GOOD === 1 && (
                            <Form.Group className="col-md-3">
                                <Form.Label htmlFor="PH">Phone Number (Voter File)</Form.Label>
                                <Form.Control type="tel" id="PH" name="PH" defaultValue={this.state.voter.PH} readOnly disabled />
                            </Form.Group>
                        )}
                        {this.state.voter.PH && this.state.voter.PH_GOOD === 1 && (
                            <Form.Group className="col-md-2 divToggle">
                                <Form.Label htmlFor="PH_GOOD">
                                    <i className="fas fa-arrow-left" aria-hidden="true"></i> Number Good?
                                </Form.Label>
                                <Form.Control as="select" id="PH_GOOD" name="PH_GOOD" defaultValue="1" readOnly={this.state.disableInput} disabled={this.state.disableInput}>
                                    <option value="1" key={1}>
                                        Yes
                                    </option>
                                    <option value="0" key={0}>
                                        No
                                    </option>
                                </Form.Control>
                            </Form.Group>
                        )}
                        <Form.Group className="col-md-3 divToggle">
                            <Form.Label htmlFor="ALTPH">Alt. Phone Number (Database)</Form.Label>
                            <Form.Control
                                type="tel"
                                id="ALTPH"
                                name="ALTPH"
                                defaultValue={this.state.voter.ALTPH || ''}
                                onInput={this.handleChange}
                                readOnly={this.state.disableInput}
                                disabled={this.state.disableInput}
                            />
                        </Form.Group>
                        <Form.Group className="col-md-3 divToggle">
                            <Form.Label htmlFor="EMAIL">Email</Form.Label>
                            <Form.Control
                                type="email"
                                id="EMAIL"
                                name="EMAIL"
                                defaultValue={this.state.voter.EMAIL || ''}
                                onInput={this.handleChange}
                                readOnly={this.state.disableInput}
                                disabled={this.state.disableInput}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className="col-md-1 divToggle">
                            <Form.Label htmlFor="ST">Street #</Form.Label>
                            <Form.Control
                                type="number"
                                id="ST"
                                name="ST"
                                defaultValue={this.state.voter.ST || ''}
                                onInput={this.handleChange}
                                readOnly={this.state.disableInput}
                                disabled={this.state.disableInput}
                            />
                        </Form.Group>
                        <Form.Group className="col-md-1 divToggle">
                            <Form.Label htmlFor="SUFFIX_A">Suffix</Form.Label>
                            <Form.Control
                                type="text"
                                id="SUFFIX_A"
                                name="SUFFIX_A"
                                defaultValue={this.state.voter.SUFFIX_A || ''}
                                onInput={this.handleChange}
                                readOnly={this.state.disableInput}
                                disabled={this.state.disableInput}
                            />
                        </Form.Group>
                        <Form.Group className="col-md-3 divToggle">
                            <Form.Label htmlFor="STNME">Street</Form.Label>
                            <Form.Control as="select" id="STNME" name="STNME" value={this.state.voter.STNME} onInput={this.handleChange} disabled={this.state.disableInput}>
                                {this.createStreetOptions()}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="col-md-1 divToggle">
                            <Form.Label htmlFor="APT">Apt #</Form.Label>
                            <Form.Control
                                type="text"
                                id="APT"
                                name="APT"
                                defaultValue={this.state.voter.APT || ''}
                                onInput={this.handleChange}
                                readOnly={this.state.disableInput}
                                disabled={this.state.disableInput}
                            />
                        </Form.Group>
                        <Form.Group className="col-md-2 divToggle">
                            <Form.Label htmlFor="TWN">Town</Form.Label>
                            <Form.Control
                                type="text"
                                id="TWN"
                                name="TWN"
                                defaultValue={this.state.voter.TWN}
                                onInput={this.handleChange}
                                readOnly={this.state.disableInput}
                                disabled={this.state.disableInput}
                            />
                        </Form.Group>
                        <Form.Group className="col-md-1 divToggle">
                            <Form.Label htmlFor="ZIP">ZIP</Form.Label>
                            <Form.Control
                                type="text"
                                id="ZIP"
                                name="ZIP"
                                defaultValue={this.state.voter.ZIP}
                                onInput={this.handleChange}
                                readOnly={this.state.disableInput}
                                disabled={this.state.disableInput}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className="col-md-2 divToggle">
                            <Form.Label htmlFor="W">Ward</Form.Label>
                            <Form.Control
                                type="number"
                                id="W"
                                name="W"
                                defaultValue={this.state.voter.W}
                                onInput={this.handleChange}
                                readOnly={this.state.disableInput}
                                disabled={this.state.disableInput}
                            />
                        </Form.Group>
                        <Form.Group className="col-md-2 divToggle">
                            <Form.Label htmlFor="d">District</Form.Label>
                            <Form.Control
                                type="number"
                                id="d"
                                name="d"
                                defaultValue={this.state.voter.d}
                                onInput={this.handleChange}
                                readOnly={this.state.disableInput}
                                disabled={this.state.disableInput}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th scope="col">General 2018</th>
                                <th scope="col">Primary 2018</th>
                                <th scope="col">General 2017</th>
                                <th scope="col">Primary 2017</th>
                                <th scope="col">General 2016</th>
                                <th scope="col">Primary 2016</th>
                                <th scope="col">General 2015</th>
                                <th scope="col">Primary 2015</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{this.state.voter.G18 || 'N/A'}</td>
                                <td>{this.state.voter.P18 || 'N/A'}</td>
                                <td>{this.state.voter.G17 || 'N/A'}</td>
                                <td>{this.state.voter.P17 || 'N/A'}</td>
                                <td>{this.state.voter.G16 || 'N/A'}</td>
                                <td>{this.state.voter.P16 || 'N/A'}</td>
                                <td>{this.state.voter.G15 || 'N/A'}</td>
                                <td>{this.state.voter.P15 || 'N/A'}</td>
                            </tr>
                        </tbody>
                    </Table>
                    <Form.Row>
                        <Form.Group className="col-lg-12 divToggle">
                            <Form.Label htmlFor="NOTES">Notes / Problem</Form.Label>
                            <Form.Control
                                as="textarea"
                                id="NOTES"
                                name="NOTES"
                                rows="3"
                                defaultValue={this.state.voter.NOTES}
                                onInput={this.handleChange}
                                readOnly={this.state.disableInput}
                                disabled={this.state.disableInput}
                            ></Form.Control>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className="col-lg-12 divToggle">
                            <Form.Label htmlFor="RESULT">Result</Form.Label>
                            <Form.Control
                                as="textarea"
                                id="RESULT"
                                name="RESULT"
                                rows="3"
                                defaultValue={this.state.voter.RESULT}
                                onInput={this.handleChange}
                                readOnly={this.state.disableInput}
                                disabled={this.state.disableInput}
                            ></Form.Control>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className="col-sm-4 divToggle">
                            <Form.Label htmlFor="SUPP">Can we expect their support?</Form.Label>
                            <Form.Control as="select" id="SUPP" name="SUPP" value={this.state.voter.SUPP} onChange={this.handleChange} disabled={this.state.disableInput}>
                                <option value="1">Yes</option>
                                <option value="2">No</option>
                                <option value="0">Unsure</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="col-sm-4 divToggle">
                            <Form.Label htmlFor="SUPP_PAST">Did they support us in the past?</Form.Label>
                            <Form.Control as="select" id="SUPP_PAST" name="SUPP_PAST" value={this.state.voter.SUPP_PAST} onChange={this.handleChange} disabled={this.state.disableInput}>
                                <option value="1">Yes</option>
                                <option value="2">No</option>
                                <option value="0">Unsure</option>
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className="col-sm-4 divToggle">
                            <Form.Label htmlFor="SIGN">Can we put a sign up?</Form.Label>
                            <Form.Control as="select" id="SIGN" name="SIGN" value={this.state.voter.SIGN} onChange={this.handleChange} disabled={this.state.disableInput}>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="col-sm-4 divToggle">
                            <Form.Label htmlFor="SIGN_PAST">Did they let us put a sign up in the past?</Form.Label>
                            <Form.Control as="select" id="SIGN_PAST" name="SIGN_PAST" value={this.state.voter.SIGN_PAST} onChange={this.handleChange} disabled={this.state.disableInput}>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className="col-sm-4 divToggle">
                            <Form.Label htmlFor="WEDDING">Did the Mayor marry them?</Form.Label>
                            <Form.Control as="select" id="WEDDING" name="WEDDING" value={this.state.voter.WEDDING} onChange={this.handleChange} disabled={this.state.disableInput}>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                    <Button variant="primary" id="submitBtn" onClick={() => this.setState({ showSubmit: true })}>
                        Edit Voter <FontAwesomeIcon icon={['far', 'user-edit']} />
                    </Button>{' '}
                    <Button variant="danger" id="deleteBtn" onClick={() => this.setState({ showDelete: true })}>
                        Request to Delete <FontAwesomeIcon icon={['far', 'trash-alt']} />
                    </Button>
                    <Modal id="confirm-submit" show={this.state.showSubmit} onHide={() => this.setState({ showSubmit: false })} backdrop="static" keyboard={false} centered>
                        <Modal.Header closeButton>
                            <h5 className="modal-title">Confirm Changes</h5>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure you want to make these changes?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="button" variant="primary" name="submit" id="submit" onClick={this.saveChanges}>
                                Save changes
                            </Button>
                            <Button type="button" variant="secondary" data-dismiss="modal" onClick={() => this.setState({ showSubmit: false })}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal id="confirm-delete" show={this.state.showDelete} onHide={() => this.setState({ showDelete: false })} size="lg" backdrop="static" keyboard={false} centered>
                        <Modal.Header className="bg-danger text-white" closeButton>
                            <h5 className="modal-title">Please select a reason to delete this voter from the database...</h5>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Row>
                                <Form.Group className="col-lg-12">
                                    <Form.Control
                                        as="select"
                                        id="DEL"
                                        name="DEL"
                                        className="form-control"
                                        value={this.state.deleteType}
                                        onChange={(e) => this.setState({ deleteType: e.target.value })}
                                    >
                                        <option value="1">Deceased</option>
                                        <option value="2">Moved out of town</option>
                                        <option value="3">Other (Please specify)</option>
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group className="col-lg-12">
                                    <Form.Control
                                        as="textarea"
                                        id="REASON"
                                        name="REASON"
                                        rows="3"
                                        value={this.state.deleteReason}
                                        onInput={(e) => this.setState({ deleteReason: e.target.value })}
                                        disabled={this.state.deleteType !== '3'}
                                    ></Form.Control>
                                </Form.Group>
                            </Form.Row>
                            <Alert variant="warning">
                                NOTE: Doing this will not delete the user, but the request will be reviewed. If the voter has moved somewhere else in town, please select other and specify where they
                                have moved if you know it. This can help us re-register them in their new ward and district.
                            </Alert>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={this.deleteVoter}>
                                Delete
                            </Button>
                            <Button variant="secondary" onClick={() => this.setState({ showDelete: false })}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Container>
            </div>
        );
    }
}

EditForm.propTypes = {
    deleteVoter: PropTypes.func.isRequired,
    updateVoter: PropTypes.func.isRequired,
    getVoter: PropTypes.func.isRequired,
    getStreets: PropTypes.func.isRequired,
    voter: PropTypes.object,
    streets: PropTypes.array,
};

const mapStateToProps = (state) => ({
    updated: state.current.updated,
    voter: state.current.voter,
    streets: state.current.streets,
});

export default withRouter(connect(mapStateToProps, { getVoter, getStreets, updateVoter, deleteVoter })(EditForm));
