import { RESET_STATE, CREATE_USER, AUTH_USER, INVALID_USER, INVALID_REQUEST, UPDATE_USER, LOGOUT, PASSWORD_REQUESTED, RESET_PASSWORD, PASSWORD_RESET } from '../actions/types';

const initialState = {
    token: null,
    id: null,
    message: '',
    user: {
        firstName: '',
        lastName: '',
    },
    requested: false,
    allowReset: false,
    updated: false,
    email: '',
    counter: 0,
    admin: false,
    export: false,
    restrictions: '',
    createFlow: null,
};

export default (state = initialState, action) => {
    state.counter++;
    switch (action.type) {
        case RESET_STATE:
            return {
                ...state,
                createFlow: 0,
            };
        case AUTH_USER:
            return {
                ...state,
                id: action.id ?? state.id,
                email: action.email ?? state.email,
                token: action.token ?? state.token,
                user: action.user ?? state.user,
                admin: action.admin ?? state.admin,
                export: action.export ?? state.export,
                restrictions: action.restrictions ?? state.restrictions,
                message: '',
            };
        case UPDATE_USER:
            return {
                ...state,
                email: action.email ?? state.email,
                user: action.user ?? state.user,
                message: 'Updated successfully...',
                updated: true,
            };
        case INVALID_REQUEST:
            return {
                ...state,
                updated: false,
                requested: false,
                allowReset: false,
                message: action.message,
                createFlow: action.createFlow ?? state.createFlow,
            };
        case INVALID_USER:
            return {
                ...state,
                id: null,
                email: '',
                token: null,
                message: action.message,
            };
        case PASSWORD_REQUESTED:
            return {
                ...state,
                message: action.message,
                requested: true,
                updated: false,
            };
        case RESET_PASSWORD: {
            return {
                ...state,
                message: action.message,
                allowReset: true,
            };
        }
        case PASSWORD_RESET: {
            return {
                ...state,
                message: action.message,
                updated: true,
            };
        }
        case LOGOUT:
            return {
                ...state,
                id: null,
                email: '',
                token: null,
                message: 'Successfully Logged Out...',
            };
        case CREATE_USER:
            if (action.createFlow && action.createFlow === 1) {
                return {
                    ...state,
                    message: action.message,
                    createFlow: action.createFlow,
                    user: {
                        firstName: '',
                        lastName: '',
                    },
                };
            } else {
                return {
                    ...state,
                    createFlow: action.createFlow ?? state.createFlow,
                    user: action.user ?? state.user,
                };
            }
        default:
            return state;
    }
};
