import {
    AUTH_USER,
    INVALID_USER,
    INVALID_REQUEST,
    CREATE_USER,
    UPDATE_USER,
    LOGOUT,
    PASSWORD_REQUESTED,
    RESET_PASSWORD,
    PASSWORD_RESET,
    RESET_STATE,
} from "./types";

import axios from "axios";

const transport = axios.create({
    withCredentials: true,
});

export const authUser = (user) => (dispatch) => {
    transport
        .post(process.env.REACT_APP_API_URL + "/auth", user)
        .then((res) =>
            dispatch({
                type: AUTH_USER,
                token: res.data.id,
                email: user.email,
                user: res.data,
                admin: res.data.admin,
                export: res.data.export,
                restrictions: res.data.restrictions ?? '',
                id: res.data.id,
            })
        )
        .catch((err) => {
            if (err.response) {
                dispatch({
                    type: INVALID_USER,
                    message: err.response.data.message,
                });
            }
        });
};

export const checkAuth = () => (dispatch) => {
    transport
        .get(process.env.REACT_APP_API_URL + "/auth")
        .then((res) => {
            dispatch({
                type: AUTH_USER,
            });
        })
        .catch((err) => {
            if (err.response) {
                dispatch({
                    type: INVALID_USER,
                    message: err.response.data.message.message,
                });
            }
        });
};

export const updateUser = (user) => (dispatch) => {
    transport
        .post(process.env.REACT_APP_API_URL + "/user", { user: user })
        .then((res) => {
            dispatch({
                type: UPDATE_USER,
                email: user.newEmail,
                user: {
                    firstName: user.firstName,
                    lastName: user.lastName,
                },
            });
        })
        .catch((err) => {
            if (err.response) {
                dispatch({
                    type: INVALID_REQUEST,
                    message: err.response.data.sqlMessage
                        ? err.response.data.sqlMessage
                        : err.response.data.message
                        ? err.response.data.message
                        : "An error has occurred.",
                });
            }
        });
};

export const requestNewPassword = (email) => (dispatch) => {
    transport
        .post(process.env.REACT_APP_API_URL + "/user/forgot", { email: email })
        .then((res) => {
            console.log(res.data);
            dispatch({
                type: PASSWORD_REQUESTED,
                message: res.data.message,
            });
        })
        .catch((err) => {
            if (err.response) {
                dispatch({
                    type: INVALID_REQUEST,
                    message: err.response.data.sqlMessage
                        ? err.response.data.sqlMessage
                        : err.response.data.message
                        ? err.response.data.message
                        : "An error has occurred.",
                });
            }
        });
};

export const verifyReset = (token) => (dispatch) => {
    transport
        .get(process.env.REACT_APP_API_URL + "/user/reset/" + token)
        .then((res) => {
            dispatch({
                type: RESET_PASSWORD,
                message: ''
            });
        })
        .catch((err) => {
            if (err.response) {
                dispatch({
                    type: INVALID_REQUEST,
                    message: err.response.data.sqlMessage
                        ? err.response.data.sqlMessage
                        : err.response.data.message
                        ? err.response.data.message
                        : "An error has occurred.",
                });
            }
        });
};

export const resetPassword = (token, password) => (dispatch) => {
    transport
        .post(process.env.REACT_APP_API_URL + "/user/reset/", {
            token: token,
            password: password,
        })
        .then((res) => {
            dispatch({
                type: PASSWORD_RESET,
                message: res.data.message,
            });
        })
        .catch((err) => {
            if (err.response) {
                dispatch({
                    type: INVALID_REQUEST,
                    message: err.response.data.sqlMessage
                        ? err.response.data.sqlMessage
                        : err.response.data.message
                        ? err.response.data.message
                        : "An error has occurred.",
                });
            }
        });
};

export const logout = () => (dispatch) => {
    transport
        .get(process.env.REACT_APP_API_URL + "/logout")
        .then((res) => {
            dispatch({
                type: LOGOUT,
            });
        })
        .catch((err) => {
            if (err.response) {
                dispatch({
                    type: INVALID_REQUEST,
                    message: err.response.data.sqlMessage
                        ? err.response.data.sqlMessage
                        : err.response.data.message
                        ? err.response.data.message
                        : "An error has occurred.",
                });
            }
        });
};

export const verifyCreate = (token) => (dispatch) => {
    dispatch({
        type: RESET_STATE,
    });
    transport
        .get(process.env.REACT_APP_API_URL + "/user/create/" + token)
        .then((res) => {
            dispatch({
                type: CREATE_USER,
                createFlow: 0,
                user: res.data,
            });
        })
        .catch((err) => {
            if (err.response) {
                dispatch({
                    type: INVALID_REQUEST,
                    message: err.response.data.sqlMessage
                        ? err.response.data.sqlMessage
                        : err.response.data.message
                        ? err.response.data.message
                        : "An error has occurred.",
                    createFlow: -1,
                });
            }
        });
};

export const confirmUser = (token, user) => (dispatch) => {
    transport
        .post(process.env.REACT_APP_API_URL + "/user/create/" + token, {
            user: user,
        })
        .then((res) => {
            dispatch({
                type: CREATE_USER,
                createFlow: 1,
                message: res.data.message,
            });
        })
        .catch((err) => {
            if (err.response) {
                dispatch({
                    type: INVALID_REQUEST,
                    message: err.response.data.sqlMessage
                        ? err.response.data.sqlMessage
                        : err.response.data.message
                        ? err.response.data.message
                        : "An error has occurred.",
                });
            }
        });
};
