import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getVoters } from '../redux/actions/voterActions';
import { Table, Container, Alert } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import SearchBar from './SearchBar';
import qs from 'qs';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class VoterTable extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.props.getVoters(this.props.filters);
    }

    componentDidUpdate = (prevProps, prevState) => {
        this.scrollToRef();
    };

    scrollToRef = () => {
        if(this.myRef.current) {
            window.scrollTo(0, this.myRef.current.offsetTop);
        }
    };

    editVoter = (id) => {
        this.props.history.push('/dashboard/voter/' + id);
    };

    render = () => {
        let query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        // console.log(this.props.voters);
        const voters = this.props.voters ? (
            this.props.voters.map((voter) => {
                let className = query.last === voter.VOTERID ? 'edit last' : 'edit';
                className = voter.REQUEST_DELETE > 0 ? 'edit deleted' : className;
                return (<tr className={className} key={voter.id} ref={query.last === voter.VOTERID ? this.myRef : ''} onClick={() => this.editVoter(voter.VOTERID)}>
                    <td>{voter.VOTERID}</td>
                    <td>{voter.LSTNME}</td>
                    <td>{voter.FSTNME}</td>
                    <td>{voter.ADDR}</td>
                    <td>{moment(voter.DOB).format('MM/DD/YYYY')}</td>
                    <td>{voter.PH}</td>
                    <td>{voter.ALTPH}</td>
                    <td>{voter.EMAIL}</td>
                    <td>{voter.W}</td>
                    <td>{voter.d}</td>
                </tr>)
            })
        ) : (
            <tr></tr>
        );
        return (
            <div>
                <SearchBar />
                <div className="table-responsive">
                    <Table>
                        <thead>
                            <tr className="bg-danger text-white">
                                <th scope="col">ID#</th>
                                <th scope="col">Last Name</th>
                                <th scope="col">First Name</th>
                                <th scope="col">Address</th>
                                <th scope="col">DOB</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Alt. Phone</th>
                                <th scope="col">Email</th>
                                <th scope="col">Ward</th>
                                <th scope="col">District</th>
                            </tr>
                        </thead>
                        <tbody>{voters}</tbody>
                    </Table>
                    {this.props.count <= 0 && (
                        <Container>
                            <Alert color="danger">
                                <FontAwesomeIcon icon={['far', 'exclamation-circle']} /> <strong>Sorry!</strong> There were no voters found for that query!
                            </Alert>
                        </Container>
                    )}
                </div>
            </div>
        );
    };
}

VoterTable.propTypes = {
    getVoters: PropTypes.func.isRequired,
    voters: PropTypes.array,
    filters: PropTypes.object
};

const mapStateToProps = (state) => {
    return {
        voters: state.list.voters,
        filters: state.list.filters
    };
};

export default withRouter(connect(mapStateToProps, { getVoters })(VoterTable));
