export const RESET_STATE = 'RESET_STATE';
export const AUTH_USER = 'AUTH_USER';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const INVALID_USER = 'INVALID_USER';
export const INVALID_REQUEST = 'INVALID_REQUEST';
export const LOGOUT = 'LOGOUT';
export const GET_VOTERS = 'GET_VOTERS';
export const GET_VOTER = 'GET_VOTER';
export const GET_STREETS = 'GET_STREETS';
export const UPDATE_VOTER = 'UPDATE_VOTER';
export const DELETE_VOTER = 'DELETE_VOTER';
export const NOT_FOUND = 'NOT_FOUND';
export const GET_STATS = 'GET_STATS';
export const PASSWORD_REQUESTED = 'PASSWORD_REQUESTED';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const GET_USERS = 'GET_USERS'; 
export const ADMIN_DELETE_USER = 'ADMIN_DELETE_USER';
export const ADMIN_LOCK_USER = 'ADMIN_LOCK_USER';
export const ADMIN_UNLOCK_USER = 'ADMIN_UNLOCK_USER';
export const ADMIN_UPDATE_USER = 'ADMIN_UPDATE_USER';
export const ADMIN_REQUEST_PASSWORD = 'ADMIN_REQUEST_PASSWORD';
export const ADMIN_ADD_USER = 'ADMIN_ADD_USER';
export const EXPORT_VOTERS = 'EXPORT_VOTERS';