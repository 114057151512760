import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    InputGroup,
    Button,
    FormControl,
    Form,
    Collapse,
} from "react-bootstrap";
import { getStreets, getVoters } from "../redux/actions/voterActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const checkFilters = (filters) => {
    let defaultFilters = {
        FULLNME: "",
        STNME: "%",
        PTY: "%",
        ADDR: "",
        W: "%",
        SUPP: "%",
        PH: "",
        d: "%",
        SIGN: "%",
    };
    let arr = Object.keys(filters);
    let advFilters = ["ADDR", "W", "SUPP", "PH", "d", "SIGN"];
    for (var i = 0; i < arr.length; i++) {
        if (
            advFilters.indexOf(arr[i]) > -1 &&
            filters[arr[i]] !== defaultFilters[arr[i]]
        ) {
            return true;
        }
    }
    return false;
};

class SearchBar extends React.Component {
    constructor(props) {
        super(props);
        this.props.getStreets();
        this.state = {
            open: checkFilters(this.props.filters),
            filters: this.props.filters,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.streets !== this.props.streets) {
            this.setState({
                streets: this.props.streets,
            });
        }
    };

    createStreetOptions = () => {
        let streets = [
            <option key={-1} value="%">
                -- Any Street --
            </option>,
        ];
        for (var i = 0; i < this.props.streets.length; i++) {
            streets.push(
                <option value={this.props.streets[i].STNME} key={i}>
                    {this.props.streets[i].STNME}
                </option>
            );
        }
        return streets;
    };

    createPartyOptions = () => {
        let pty = [
            "DEM",
            "REP",
            "UNA",
            "CNV",
            "CON",
            "LIB",
            "NAT",
            "GRE",
            "SSP",
            "RFP",
        ];
        let parties = [
            <option key={-1} value="%">
                -- Any Party --
            </option>,
        ];
        for (var i = 0; i < pty.length; i++) {
            parties.push(
                <option value={pty[i]} key={i}>
                    {pty[i]}
                </option>
            );
        }
        return parties;
    };

    getNth = (i) => {
        let mod = i % 10;
        let hundo = i % 100;
        if (hundo > 10 && hundo < 20) {
            mod = 0;
        }
        switch (mod) {
            case 1:
                return `${i}st`;
            case 2:
                return `${i}nd`;
            case 3:
                return `${i}rd`;
            default:
                return `${i}th`;
        }
    };

    handleChange = (e) => {
        let filters = this.state.filters;
        filters[e.target.id] = e.target.value;
        this.setState({
            filters: filters,
        });
        this.props.getVoters(filters);
    };

    clearFilters = () => {
        this.setState({
            filters: {
                FULLNME: "",
                STNME: "%",
                PTY: "%",
                ADDR: "",
                W: "%",
                SUPP: "%",
                PH: "",
                d: "%",
                SIGN: "%",
            },
        });
        this.props.getVoters({
            FULLNME: "",
            STNME: "%",
            PTY: "%",
            ADDR: "",
            W: "%",
            SUPP: "%",
            PH: "",
            d: "%",
            SIGN: "%",
        });
    };

    render = () => {
        const wards = [
            <option key={-1} value="%">
                -- Any Ward --
            </option>,
        ];
        const restrictions = this.props.restrictions.split(',');
        for (let i = 1; i <= 10; i++) {
            if (restrictions.length === 0 || restrictions.includes(`${i}`)) {
                wards.push(
                    <option key={i} value={i}>
                        {this.getNth(i)} Ward
                    </option>
                );
            }
        }
        const districts = [
            <option key={-1} value="%">
                -- Any District --
            </option>,
        ];
        for (let i = 1; i <= 5; i++) {
            districts.push(
                <option key={i} value={i}>
                    {this.getNth(i)} District
                </option>
            );
        }
        return (
            <div>
                <Container fluid>
                    <Row className="mb-2">
                        <Col>
                            <h4>Search Filters</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Full Name</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder="Full Name"
                                    id="FULLNME"
                                    value={this.state.filters.FULLNME}
                                    onInput={this.handleChange}
                                    onChange={this.handleChange}
                                    aria-label="Voter's Full Name"
                                ></FormControl>
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Street</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select"
                                    id="STNME"
                                    value={this.state.filters.STNME}
                                    onChange={this.handleChange}
                                    name="STNME"
                                >
                                    {this.createStreetOptions()};
                                </Form.Control>
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        Party Affiliation
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select"
                                    id="PTY"
                                    value={this.state.filters.PTY}
                                    onChange={this.handleChange}
                                    name="PTY"
                                >
                                    {this.createPartyOptions()}
                                </Form.Control>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Collapse in={this.state.open} id="advanced-search">
                        <Row>
                            <Col>
                                <InputGroup className="mb-2">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>
                                            Address
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                        placeholder="Address"
                                        id="ADDR"
                                        value={this.state.filters.ADDR}
                                        onChange={this.handleChange}
                                        onInput={this.handleChange}
                                        aria-label="Voter's Address"
                                    ></FormControl>
                                </InputGroup>
                                <InputGroup className="mb-2">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>
                                            Phone #
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                        placeholder="Phone"
                                        id="PH"
                                        value={this.state.filters.PH}
                                        onChange={this.handleChange}
                                        onInput={this.handleChange}
                                        aria-label="Voter's Phone Number"
                                    ></FormControl>
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup className="mb-2">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Ward</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        as="select"
                                        id="W"
                                        value={this.state.filters.W}
                                        onChange={this.handleChange}
                                        name="W"
                                    >
                                        {wards}
                                    </Form.Control>
                                </InputGroup>
                                <InputGroup className="mb-2">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>
                                            District
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        as="select"
                                        id="d"
                                        value={this.state.filters.d}
                                        onChange={this.handleChange}
                                        name="d"
                                    >
                                        {districts}
                                    </Form.Control>
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup className="mb-2">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>
                                            Supporter?
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        as="select"
                                        id="SUPP"
                                        value={this.state.filters.SUPP}
                                        onChange={this.handleChange}
                                        name="SUPP"
                                    >
                                        <option value="%">-- NA --</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                        <option value="2">Unsure</option>
                                    </Form.Control>
                                </InputGroup>
                                <InputGroup className="mb-2">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Sign?</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        as="select"
                                        id="SIGN"
                                        value={this.state.filters.SIGN}
                                        onChange={this.handleChange}
                                        name="SIGN"
                                    >
                                        <option value="%">-- NA --</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                        <option value="2">Unsure</option>
                                    </Form.Control>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Collapse>
                    <hr />
                    <Row className="justify-content-end">
                        <Col md={3}>
                            <Button
                                variant="danger"
                                className="mb-2 ml-1 float-right"
                                size="sm"
                                onClick={this.clearFilters}
                            >
                                Clear Filters{" "}
                                <FontAwesomeIcon icon={["far", "trash-alt"]} />
                            </Button>
                            <Button
                                variant="info"
                                className="mb-2 ml-1 float-right"
                                size="sm"
                                onClick={() =>
                                    this.setState({ open: !this.state.open })
                                }
                                aria-controls="advanced-search"
                                aria-expanded={this.state.open}
                            >
                                Advanced Search{" "}
                                <FontAwesomeIcon
                                    icon={["far", "search-plus"]}
                                />
                            </Button>
                            <Button
                                variant="success"
                                className="mb-2 ml-1 float-right"
                                size="sm"
                                href="/dashboard/export"
                                style={{
                                    display: this.props.canExport
                                        ? "block"
                                        : "none",
                                }}
                            >
                                Export{" "}
                                <FontAwesomeIcon
                                    icon={["far", "file-export"]}
                                />
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    };
}

const mapStateToProps = (state) => ({
    streets: state.current.streets,
    filters: state.list.filters,
    canExport: state.auth.export,
    restrictions: state.auth.restrictions,
});

export default withRouter(
    connect(mapStateToProps, { getStreets, getVoters })(SearchBar)
);
