import { combineReducers } from 'redux';
import auth from './auth';
import list from './list';
import current from './current';
import stats from './stats';
import admin from './admin';

export default combineReducers({
    auth: auth,
    list: list,
    current: current,
    stats: stats,
    admin: admin
});