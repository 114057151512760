import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from './components/Login';
import Forgot from './components/Forgot';
import Reset from './components/Reset';
import Create from './components/Create';
import Dashboard from './components/Dashboard';
import 'bootstrap/dist/css/bootstrap.min.css';
import { withCookies } from 'react-cookie';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/pro-regular-svg-icons';

library.add(far);

class App extends React.Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route exact path="/" render={() => <Login cookies={this.props.cookies} />} />
                    <Route exact path="/forgot" render={() => <Forgot cookies={this.props.cookies} />} />
                    <Route path="/reset/:id" children={<Reset cookies={this.props.cookies} />} />
                    <Route path="/create/:id" children={<Create cookies={this.props.cookies} />} />
                    <Route exact path="/dashboard" render={() => <Dashboard cookies={this.props.cookies} />} />
                    <Route exact path="/dashboard/stats" render={() => <Dashboard cookies={this.props.cookies} />} />
                    <Route exact path="/dashboard/export" render={() => <Dashboard cookies={this.props.cookies} />} />
                    <Route exact path="/dashboard/users" render={() => <Dashboard cookies={this.props.cookies} />} />
                    <Route exact path="/dashboard/settings" render={() => <Dashboard cookies={this.props.cookies} />} />
                    <Route path="/dashboard/voter/:id" render={() => <Dashboard cookies={this.props.cookies} />} />
                </Switch>
            </div>
        );
    }
}

export default withCookies(App);
